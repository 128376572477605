import api from './api';

export const fetchAccounts = async () => {
  const response = await api.get(`/admin/accounts`);
  return response.data;
};

export const fetchAccount = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/accounts/${id}`);
  return response.data;
};

export const createAccount = async (data) => {
  const response = await api.post(`/admin/accounts/create`, data);
  return response.data;
};

export const updateAccount = async (data) => {
  const response = await api.patch(`/admin/accounts/update`, data);
  return response.data;
}

export const suspendAccount = async (id) => {
  const response = await api.put(`/admin/accounts/${id}/suspend`);
  return response.data;
};

export const deleteAccount = async (id) => {
  const response = await api.delete(`/admin/accounts/${id}`);
  return response.data;
};

export const createRole = async (data) => {
  const response = await api.post(`/admin/role-permission/create/role`, data);
  return response.data;
}

export const getRoles = async () => {
  const response = await api.get(`/admin/role-permission/role`);
  return response.data;
}

export const getRole = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/role-permission/role/${id}`);
  return response.data;
}

export const updateRole = async (data) => {
  const response = await api.patch(`/admin/role-permission/update/role`, data);
  return response.data;
}

export const deleteRole = async (id) => {
  const response = await api.delete(`/admin/role-permission/delete/role/${id}`);
  return response.data;
}

export const createModule = async (data) => {
  const response = await api.post(`/admin/role-permission/create/modules`, data);
  return response.data
}

export const getModules = async () => {
  const response = await api.get(`/admin/role-permission/modules`);
  return response.data;
}

export const updateModule = async (data) => {
  const response = await api.patch(`/admin/role-permission/update/module`, data);
  return response.data;
}

export const deleteModule = async (data) => {
  const response = await api.delete(`/admin/role-permission/delete/module`, data);
  return response.data;
}