import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { ReactComponent as LogoutIcon } from '@/assets/icons/logout.icon.svg';
import { ReactComponent as Logo } from '@/assets/icons/logo.svg';
import { routes } from '@/constants/routes';
import { logoutUser } from '@/api/authApi';
import { useDispatch } from 'react-redux';
import { resetAppStateOnLogout } from '@/redux/features/appSlice';
import secureLocalStorage from 'react-secure-storage';

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = useMutation(logoutUser, {
    onSuccess: async () => {
      dispatch(resetAppStateOnLogout());
      secureLocalStorage.clear();
      localStorage.clear();
      navigate('/login');
    },
    onError: (error) => {
      // toast.error(error?.response?.data?.message);
      dispatch(resetAppStateOnLogout());
      secureLocalStorage.clear();
      localStorage.clear();
      navigate('/login');
    }
  });

  return (
    <View>
      <div className="content">
        {/* ======= Store Name ===== */}
        <div className="logo">
          <Logo />
        </div>

        {/* ====== Nav Links ====== */}
        <div className="nav-links">
          {routes.map((route) => {
            return (
              <div className="group" key={route?.groupName}>
                <p className="category">{route?.groupName}</p>
                {route?.routes?.map((n) => {
                  const Icon = n?.Icon;
                  return (
                    <Link
                      to={n?.path}
                      key={n?.name}
                      className={location.pathname.includes(n?.mainPath) ? 'active' : ''}>
                      <Icon
                        className={location.pathname.includes(n?.mainPath) ? 'active-icon' : 'icon'}
                      />
                      {n?.label ? <span className="label">{n?.label}</span> : null}
                      {n?.name}
                    </Link>
                  );
                })}
              </div>
            );
          })}
          <div className="group">
            <p className="category">Misc</p>
            <button className="logout" onClick={() => handleLogout.mutate({})}>
              <LogoutIcon />
              {handleLogout.isLoading ? <span>Logging out...</span> : <span>Logout</span>}
            </button>
          </div>
        </div>
      </div>
    </View>
  );
}

const View = styled.nav`
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 18%;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 20px;
  border-right: 1px solid ${({ theme }) => theme.colors?.line};
  position: fixed;
  box-shadow: rgba(131, 131, 131, 0.02) 0px 3px 5px;
  z-index: 9;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .logo {
      width: 100%;
      margin-bottom: 30px;
      padding: 0px 20px;

      svg {
        width: 100px;
        height: 40px;
        padding: 0;
      }
    }

    .nav-links {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px 20px;
      padding-top: 10px;

      .group {
        margin-bottom: 16px;
        .category {
          font-size: 0.7rem;
          font-weight: 400;
          text-transform: uppercase;
          color: ${({ theme }) => theme.colors?.info};
          margin-bottom: 10px;
        }

        a {
          width: 100%;
          height: 45px;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 11.7px;
          color: ${({ theme }) => theme.colors?.body};
          margin-bottom: 5px;
          padding: 12px 10px;
          transition: all 0.2s ease;
          position: relative;

          &:hover {
            background-color: ${({ theme }) => theme.colors?.line};
            border-radius: 6px;
          }

          .label {
            position: absolute;
            bottom: 0px;
            right: 0px;
            font-size: 0.5rem;
            padding: 2px;
            border-radius: 4px;
            background-color: ${({ theme }) => theme.colors?.white};
          }

          img {
            margin-right: 16px;
          }

          .icon {
            margin-right: 16px;

            path {
              stroke-width: 1.5;
            }
          }

          .active-icon {
            margin-right: 16px;
          }

          .active-icon path {
            stroke: ${({ theme }) => theme.colors?.secondary};
            /* fill: ${({ theme }) => theme.colors.primary_400}; */
          }

          .active-icon ellipse {
            stroke: ${({ theme }) => theme.colors?.secondary};
            /* fill: ${({ theme }) => theme.colors.primary_400}; */
          }
        }

        .active {
          color: ${({ theme }) => theme.colors?.secondary};
          background-color: ${({ theme }) => theme.colors?.line};
          border-radius: 6px;
          font-weight: 500;

          .icon {
            margin-right: 16px;
          }

          img {
            margin-right: 16px;
          }
        }

        .logout {
          width: 100%;
          height: 45px;
          display: flex;
          align-items: center;

          color: ${({ theme }) => theme.colors?.body};
          margin-bottom: 16px;
          padding: 10px;
          transition: all 0.2s ease;
          background-color: transparent;
          cursor: pointer;
          border: none;
          outline: none;

          &:hover {
            background-color: ${({ theme }) => theme.colors?.line};
            border-radius: 6px;
          }

          span {
            margin-left: 16px;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 11.7px;
          }
        }
      }
    }
  }
`;
