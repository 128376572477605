import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.icon.svg';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { markAsCompleted } from '@/api/invoiceDiscounting';
import { useNavigate } from 'react-router-dom';

function MarkAsCompletedModal({ showModal, setShowModal, selectedItemId }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const markInvoiceAsCompleted = useMutation({
    mutationFn: ({ id }) => markAsCompleted(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['approved']);
      navigate('/invoice-discount?tab=invoice-discount&view=completed')
      toast.success('This invoice has been completed');
    }
  });

  const handleCompleted = () => {
    markInvoiceAsCompleted.mutate({ id: selectedItemId });
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <div className="header">
                <button type="button" onClick={() => setShowModal(false)}>
                  <CloseIcon />
                </button>
              </div>
              <ContentView>
                <div className="content">
                  <h3>Mark as Completed</h3>
                  <p>Are you sure you want to mark this invoice as completed?</p>

                  <div className="info">
                    <div className="icon">
                      <InfoIcon />
                    </div>
                    <p>
                      Taking this action confirms that the user has completed payment for this invoice
                    </p>
                  </div>
                  <div className="btn">
                    <button type="button" className="continue" onClick={() => setShowModal(false)}>
                      No, Cancel
                    </button>
                    <button type="button" className="activate" onClick={() => handleCompleted()}>
                      {markInvoiceAsCompleted.isLoading ? (
                        <Oval height={20} width={20} color="#c2bdbd" secondaryColor="#ddd" />
                      ) : (
                        'Yes, Mark as Completed'
                      )}
                    </button>
                  </div>
                </div>
              </ContentView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default MarkAsCompletedModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .info {
      width: 100%;
      background-color: ${(props) => props.theme.colors?.warningBackground};
      border-radius: 6px;
      padding: 14px 5px;
      padding-bottom: 5px;
      display: flex;
      margin-bottom: 10px;

      .icon {
        margin-right: 10px;
        margin-left: 10px;
      }

      p {
        font-size: 0.9rem;
        font-weight: 400;
        margin-bottom: 12px;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }

    .green-icon {
      path {
        stroke: ${(props) => props.theme.colors?.warning};
      }
    }

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 10px;
      color: ${(props) => props.theme.colors?.secondary};
      margin-top: 10px;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors?.darkGray};
      margin-bottom: 20px;
      line-height: 19px;
    }

    .warn {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      margin: 10px 0;
      background-color: #fdebeb;
      font-weight: 500;
      border-radius: 10px;

      h2 {
        width: 100%;
        font-weight: 500;
        line-height: 19px;
        color: ${(props) => props.theme.colors?.error_200};
      }
    }

    .btn {
      width: 100%;
      gap: 1rem;
      display: flex;
      align-items: center;

      .activate {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        background-color: ${(props) => props.theme.colors?.success};
        height: 64px;
        border-radius: 16px;
        color: ${(props) => props.theme.colors?.white};
        font-weight: 500;
      }

      .continue {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        background-color: ${(props) => props.theme.colors?.white};
        border: 1px solid ${(props) => props.theme.colors?.info};
        height: 64px;
        border-radius: 16px;
        color: ${(props) => props.theme.colors?.info};
        font-weight: 500;
      }
    }
  }
`;