import React from 'react';
import { NumericFormat } from 'react-number-format';


function CurrencyyInput({ name, disabled, value, onValueChange, placeholder }) {
  const handleValueChange = ({ floatValue }) => {
    onValueChange(floatValue);
  };

  return (
    <NumericFormat
      displayType="input"
      className="currency-input"
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      thousandSeparator={true}
      prefix="₦"
      decimalsLimit={2}
      onValueChange={handleValueChange}
    />
  );
}

export default CurrencyyInput;