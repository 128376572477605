import api from './api';

export const fetchCompliances = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `/admin/compliance?page=${page}&limit=${limit}`;
  
  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
};

export const fetchCompliance = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/compliance/${id}`);
  return response.data;
};

export const fetchComplianceVerification = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/compliance/${id}/verifications`);
  return response.data;
};

export const approveOrRejectCompliance = async (id, data) => {
  const response = await api.post(`/admin/compliance/${id}/approve-or-reject`, data);
  return response.data;
};

export const exportCompliance = async (data) => {
  const response = await api.post(`/admin/export/compliance`, data);
  return response.data;
}