import { EmptyState } from '@/components/EmptyState';
import PageHeader from '@/components/PageHeader';
import { usePermissions } from '@/hooks/usePermissions';
import React, { useEffect } from 'react'
import styled from 'styled-components'
import LendersTable from './components/LendersTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LenderRequests } from './components/details.jsx/LenderRequests';
import { LoanInterests } from './components/details.jsx/LoanInterests';

const tabs = [
  {
    name: 'Lenders',
    path: 'lenders',
    value: 'lenders'
  },
  {
    name: 'Lenders Requests',
    path: 'requests',
    value: 'requests'
  },
  {
    name: 'Loan Interests',
    path: 'interests',
    value: 'interests'
  }
];

export const Lenders = () => {
  const { hasPermission } = usePermissions();
  const canReadLenders = hasPermission('users', 'read');

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      setSearchParams('tab=lenders');
      // Optionally, you can also navigate to the new URL
      navigate('?tab=lenders');
    }
  }, [searchParams, navigate, setSearchParams]);

  return (
    <>
      <PageHeader title='Lenders' subtitle='View and manage lenders' />
      {canReadLenders ? (
        <LendersView>
          <TabView>
            {tabs.map((item) => (
              <button
                key={item.name}
                className={`tab ${tab === item.value ? 'active' : ''}`}
                onClick={() => setSearchParams(`tab=${item.path}`)}>
                {item.name}
              </button>
            ))}
          </TabView>
          <TabContent>{tab === 'lenders' && <LendersTable />}</TabContent>
          <TabContent>{tab === 'requests' && <LenderRequests />}</TabContent>
          <TabContent>{tab === 'interests' && <LoanInterests />}</TabContent>
        </LendersView>

      ) : (
        <EmptyState />
      )}
    </>
  )
}

const LendersView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 80px;
  overflow-x: hidden;
  margin-left: 18%;
`;

const TabView = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  position: fixed;
  z-index: 100;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};

  button {
    padding: 16px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
      color: ${(props) => props.theme?.colors?.secondary};
    }
  }

  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;

const TabContent = styled.div`
  width: 100%;
`;