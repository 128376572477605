import api from "./api"

export const fetchLeaderboards = async ({ queryKey }) => {
  const [, { duration }] = queryKey;
  const response = await api.get(`/admin/leader-board?duration=${duration}`);
  return response.data;
}

export const fetchDetails = async ({ queryKey }) => {
  const [, { id, page, limit }] = queryKey;
  const response = await api.get(`admin/leader-board/${id}?page=${page}&limit=${limit}`);
  return response.data;
}