import { fetchWallet } from "@/api/walletApi";
import PageHeader from "@/components/PageHeader";
import PageLoader from "@/loaders/PageLoader";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components";
import WalletTransaction from "./components/details/CreditWalletTransaction";
import { ReactComponent as ExtLinkIcon } from '@/assets/icons/ext-link.icon.svg';
import { numberWithCommas } from "@/utils/helpers";
import { useState } from "react";
import { Tabs } from "./components/tabs/Tabs";
import { WalletInfo } from "./components/details/WalletInfo";

const WalletDetails = () => {

  return (
    <WalletDetailsView>
      <PageHeader title="Wallet Details" subtitle="View and manage Wallets" />
      <WalletInfo />
      <Tabs />
    </WalletDetailsView>
  )
}

export default WalletDetails

const WalletDetailsView = styled.div`
  width: 100%;
  height: 100%;
`;