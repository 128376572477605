import React from 'react'
import styled from 'styled-components'
import { ReactComponent as AccessDenied } from '@/assets/icons/error-access.svg'

export const EmptyState = () => {
  return (
    <EmptyView>
      <AccessDenied />
      <div className='text'>
        <h1>Oops!</h1>
        <p>You don't have the permission to view this page</p>
      </div>
    </EmptyView>
  )
}

const EmptyView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .text {
    margin-top: -2rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};

    h1 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    p {
      font-size: 1rem;
      font-weight: 400;
    }
  }
`;