import React from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styled from 'styled-components';

const toolbarOptions = {
  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  blockType: {
    inDropdown: true,
  },
  fontSize: {
    className: 'custom-fontsize',
  },
  list: {
    inDropdown: false,
  },
  textAlign: {
    inDropdown: false,
  },
};

function WYSIWYG({ editorState, onEditorStateChange, placeholder }) {
  return (
    <QuillView>
      <Editor 
        editorState={editorState}
        toolbar={toolbarOptions}
        wrapperClassName="rdw-editor-wrapper"
        editorClassName="rdw-editor-main"
        toolbarClassName="rdw-editor-toolbar"
        onEditorStateChange={onEditorStateChange}
        placeholder={placeholder}
      />
    </QuillView>
  );
}

export default WYSIWYG;

const QuillView = styled.div`
  width: 100%;
  margin-top: 10px;

  .rdw-editor-wrapper {
    margin-top: -6px;
    border-radius: 0 0 16px 16px;
    background-color: ${({ theme }) => theme.colors.inputBackground};
  }

  .rdw-editor-toolbar {
    border-bottom: 1px solid ${({ theme }) => theme.colors.line};
    background-color: ${({ theme }) => theme.colors.inputBackground};
  }

  .rdw-editor-main {
    min-height: 300px;
    max-height: 400px; 
    padding: 0 10px;
    margin-top: -6px;
    border: none;
    overflow: auto;
    border-radius: 0 0 16px 16px;
    border-top: 1px solid ${({ theme }) => theme.colors.line};
    background-color: ${({ theme }) => theme.colors.inputBackground};
  }

  .rdw-option-wrapper {
    background-color: ${({ theme }) => theme.colors.inputBackground};
  }

  .rdw-option-active {
    background-color: ${({ theme }) => theme.colors.inputBackground};
  }

  .ql-editor {
    border: none;
    border-radius: 0 0 16px 16px;
    background-color: ${({ theme }) => theme.colors.inputBackground};
  }

  .ql-toolbar {
    border: none;
    border-radius: 16px 16px 0 0;
    background-color: ${({ theme }) => theme.colors.inputBackground};
    border-bottom: 1px solid ${({ theme }) => theme.colors.line};
  }

  .ql-container {
    border: none;
    min-height: 150px;
    margin-bottom: 30px;
  }
`;