const theme = {
  colors: {
    // Natural Colors
    black: '#141414',
    white: '#ffffff',

    // Greyscale Colors
    activeTitle: '#14142B',
    body: '#4E4B66',
    label: '#6E7191',
    placeholder: '#A0A3BD',
    line: '#D9DBE9',
    inputBackground: '#EFF0F7',
    layer: '#EFF0F6',
    layer2: '#EFF6FF',
    layer3: '#F5F5F5',
    layer4: '#F8F8F8',
    layer5: '#FDFDFD',
    layer6: '#F6F6F6',
    background: '#F4F4F4',
    offWhite: '#FCFCFC',
    document: '#F9F9FA',
    info: '#9F9F9F',
    grey: '#8C8C8C',

    // Primary Colors
    primary: '#92C22C',
    primaryHover: '#80AB25',
    primaryActive: '#526C1A',
    primaryFocus: '#EEFFC9',
    secondary: '#174078',
    warning: '#F4B740',
    warningBackground: '#FFF8E5',
    error: '#ED2E7E',
    error_200: '#B00020',
    errorBackground: '#FFF3F8',
    success: '#00BA88',
    successBackground: '#E5FFF6',
    alternateBackground: '#EDF5FF',
    alternateBackground2: '#F5FFE1',
  },
};

export default theme;
