import api from "./api";
export const fetchTransactionMetrics = async({ queryKey }) => {
  const [, { fetch }] = queryKey;
  let url = `/admin/transactions/metrics/stat?fetch=${fetch}`;
  const response = await api.get(url);
  return response.data;
}

export const fetchTransactions = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `/admin/transactions?page=${page}&limit=${limit}`;
  
  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchCreditTransaction = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;
  let url = `/admin/transactions/type/credit?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  const response = await api.get(url);
  return response.data;
}
export const fetchDebitTransaction = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;
  let url = `/admin/transactions/type/debit?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchTransaction = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/transactions/${id}`);
  return response.data;
}

export const createTransactionCharge = async(data) => {
  const response = await api.post(`/admin/transactions/charge/create`, data);
  return response.data;
}

export const fetchTransactionFees = async () => {
  const response = await api.get(`/admin/transactions/charges`);
  return response.data;
}

export const fetchTransactionFee = async ({ queryKey }) => {
  const [, id] = queryKey;
  if (id) {
    const response = await api.get(`/admin/transactions/charge/${id}`);
    return response.data;
  } else {
    throw new Error('No transaction fee ID provided');
  }
}

export const updateTransactionFee = async(id, data) => {
  if (id) {
    const response = await api.put(`/admin/transactions/charge/${id}`, data);
    return response.data;
  } else {
    throw new Error('No transaction fee ID provided');
  }
}

export const exportTransaction = async (data) => {
  const response = await api.post(`/admin/export/transaction`, data);
  return response.data;
}

export const transactionReconciliation = async (data) => {
  const response = await api.post(`/admin/reconciliation/transaction`, data);
  return response.data;
}