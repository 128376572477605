import PageHeader from '@/components/PageHeader';
import { styled } from 'styled-components';
import ComplainceTable from './components/BillsTable';
import withAuthentication from '@/hooks/withAuthentication';
import BillsTable from './components/BillsTable';
import { usePermissions } from '@/hooks/usePermissions';
import { EmptyState } from '@/components/EmptyState';

function Bills() {
  const { role, hasPermission } = usePermissions();
  const canReadBills = hasPermission('bills', 'read');
  const isRoleSuper = role('Super Admin');
  const isRoleAdmin = role('Admin');

  return (
    <>
      <PageHeader title="Bills" subtitle="View and manage all Bills" />
      <BillsView>
        {canReadBills || isRoleSuper || isRoleAdmin ? <BillsTable /> : <EmptyState />}
      </BillsView>
    </>
  );
}

export default withAuthentication(Bills);

const BillsView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;
