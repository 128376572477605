import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.icon.svg';
import SearchDropdown from '@/components/SearchDropdown';
import { numberWithCommas } from '@/utils/helpers';
import { assignMultipleUsersToPlan, assignUserToPlan } from '@/api/userApi';
import Input from '@/components/Input';

function SubscribeModal({ showModal, setShowModal, subscriptions, userId, setSelectedUsers }) {
  const queryClient = useQueryClient();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedCost, setSelectedCost] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const DurationCost = [
    {
      duration: 'Monthly',
      cost: selectedPlan?.monthlyCost,
      value: 'monthly',
      id: selectedPlan?.id
    },
    {
      duration: 'Quarterly',
      cost: selectedPlan?.quarterlyCost,
      value: 'quarterly',
      id: selectedPlan?.id
    },
    {
      duration: 'Annually',
      cost: selectedPlan?.annualCost,
      value: 'annually',
      id: selectedPlan?.id
    }
  ];

  useEffect(() => {
    if (subscriptions) {
      setSelectedCost(DurationCost[0]);
    }
  }, [subscriptions]);

  const handleSubscribeToPlan = useMutation(assignUserToPlan, {
    onSuccess: () => {
      toast.success('User subscribed to plan successfully.');
      queryClient.invalidateQueries({ queryKey: ['user'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
      queryClient.invalidateQueries({ queryKey: ['userSubscription'] });
      queryClient.invalidateQueries({ queryKey: ['subScriptionsMetrics'] });
      setShowModal(false);
      if (typeof setSelectedUsers === 'function') {
        setSelectedUsers([]);
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleMultiSubscribeToPlan = useMutation(assignMultipleUsersToPlan, {
    onSuccess: () => {
      toast.success('Users subscribed to plan successfully.');
      queryClient.invalidateQueries({ queryKey: ['user'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
      queryClient.invalidateQueries({ queryKey: ['userSubscription'] });
      queryClient.invalidateQueries({ queryKey: ['subScriptionsMetrics'] });
      setShowModal(false);
      if (typeof setSelectedUsers === 'function') {
        setSelectedUsers([]);
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = () => {
    if (typeof userId === 'string') {
      const data = {
        planId: selectedPlan?.id,
        userId,
        duration: selectedCost?.value,
        startDate: selectedDate?.startDate ? new Date(selectedDate?.startDate).toISOString() : undefined,
      };

      handleSubscribeToPlan.mutate(data);
    } else {
      const data = {
        planId: selectedPlan?.id,
        userIds: userId,
        duration: selectedCost?.value,
        startDate: selectedDate?.startDate ? new Date(selectedDate?.startDate).toISOString() : undefined,
      };

      handleMultiSubscribeToPlan.mutate(data);
    }
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <ContentView>
                <HeaderView>
                  <h3>Add subscription plan</h3>
                  <button className="close" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </HeaderView>

                <Content>
                  <div className="info">
                    <div className="icon">
                      <InfoIcon />
                    </div>
                    <p>
                      Please check the compliance report and confirm that the user has met all the
                      requirements for the plan before subscribing.
                    </p>
                  </div>

                  <div className="input-view">
                    <label htmlFor="comment">Select a plan</label>
                    <SearchDropdown
                      options={subscriptions}
                      id="id"
                      name="name"
                      setValue={setSelectedPlan}
                      value={selectedPlan}
                      placeholder="Select a plan."
                    />
                    <br />
                    <label htmlFor="comment">Select a start date</label>
                    <Input
                      className='calendar'
                      type='date'
                      id={selectedDate?.startDate}
                      name={selectedDate?.startDate}
                      value={selectedDate?.startDate}
                      onChange={(e) => setSelectedDate({ startDate: e.target.value })}
                    />
                  </div>

                  {selectedPlan && (
                    <div className="input-view">
                      {selectedPlan?.name !== 'Free' && (
                        <>
                          <label>Select subscription duration</label>
                          <div className="select-view">
                            {DurationCost.map((item, index) => (
                              <div className="select-item" key={item?.duration}>
                                <div className="checkbox">
                                  <input
                                    type="checkbox"
                                    name={item?.duration}
                                    id={item?.duration}
                                    checked={selectedCost?.cost === item?.cost}
                                    onChange={() => setSelectedCost(item)}
                                  />
                                  <label htmlFor={item?.duration}>
                                    {item?.duration} - ₦{numberWithCommas(item?.cost || '00.00')}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  <div className="cta">
                    <button className="cancel" onClick={() => setShowModal(false)}>
                      Cancel
                    </button>
                    <button className="approve" onClick={onSubmit}>
                      {handleSubscribeToPlan.isLoading || handleMultiSubscribeToPlan.isLoading ? (
                        <span>
                          <Oval
                            color="#FFF"
                            secondaryColor="#ddd"
                            height={20}
                            width={20}
                            strokeWidth={4}
                            ariaLabel="loading"
                          />
                        </span>
                      ) : (
                        'Subscribe'
                      )}
                    </button>
                  </div>
                </Content>
              </ContentView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default SubscribeModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 480px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 6px;
    max-height: 90%;
    overflow-y: scroll;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;
`;

const HeaderView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
  }

  .close {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #f3f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 30px;

  .info {
    width: 100%;
    background-color: ${(props) => props.theme.colors?.warningBackground};
    border-radius: 6px;
    padding: 14px;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors?.secondary};
    }
  }

  .input-view {
    width: 100%;
    margin-top: 30px;

    .calendar {
      border-radius: 1rem;
      margin-bottom: 2rem;
    }

    label {
      font-size: 0.875rem;
    }

    .error-label {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.error_200};
      margin-top: 5px;
    }

    .select-view {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 8px;

      .select-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;

        .checkbox {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          input {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            cursor: pointer;
            accent-color: ${(props) => props.theme.colors?.secondary};
            color: ${(props) => props.theme.colors?.primary};
          }

          label {
            font-size: 1rem;
            font-weight: 500;
            color: ${(props) => props.theme.colors?.secondary};
            cursor: pointer;
          }
        }
      }
    }
  }

  .cta {
    width: 100%;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .approve {
      width: 120px;
      height: 40px;
      border-radius: 6px;
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};
      font-size: 0.9rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }
  }
`;
