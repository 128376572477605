import { fetchProfile } from "@/api/authApi"
import PageHeader from "@/components/PageHeader"
import PageLoader from "@/loaders/PageLoader"
import { useQuery } from "@tanstack/react-query"
import { format } from "date-fns"
import { styled } from "styled-components"

function Profile() {
  const profileData = useQuery({
    queryKey: ['profile'],
    queryFn: fetchProfile
  })

  const profile = profileData.data?.data;
  console.log(profile);

  if (profileData.isFetching) {
    return <PageLoader />
  }

  return (
    <>
      <ProfileView>
        <PageHeader title="Profile" subtitle="View and manage your profile" />
        <DataView>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>Firstname</h3>
                <p>{profile?.firstName || '-'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Lastname</h3>
                <p>{profile?.lastName || '-'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Username</h3>
                <p>{profile?.username || '-'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>E-mail address</h3>
                <p>{profile?.email || '-'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Role</h3>
                <p className='capitalize'>{profile?.role || profile?.roles?.name || '-'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Status</h3>
                <Status status={profile?.status}>{profile?.status}</Status>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Date Created</h3>
                <p>
                  {profile?.createdAt
                    ? format(new Date(profile?.createdAt), 'do MMM, yyyy')
                    : '--'}
                </p>
              </div>
            </div>
          </FormData>
        </DataView>
      </ProfileView>
    </>
  )
}

export default Profile

const ProfileView = styled.div`
/* width: calc(100% - 18%);
padding: 0px 30px 0px 30px; */

  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;

const Status = styled.p`
  color: ${({ status }) => {
    switch (status) {
      case 'active':
        return '#16A34A';
      case 'paid':
        return '#16A34A';
      case 'partially-paid':
        return '#F4B740';
      case 'pending':
        return '#F4B740';
      case 'draft':
        return '#F4B740';
      case 'pending':
        return '#F4B740';
      case 'ready':
        return '#F4B740';
      case 'unsent':
        return '#B91C1C';

      default:
    }
  }};
  text-transform: capitalize;
`;

const DataView = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const FormData = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: ${({ theme }) => theme?.colors?.secondary};

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
      }

      .capitalize {
        text-transform: capitalize;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;