import { createGlobalStyle, css } from 'styled-components';
import { ResetStyle } from './resetStyle';

const GlobalStyle = css`
  html,
  body {
    font-family: 'Gotham', sans-serif;
    scroll-behavior: smooth;
    font-size: 1rem;
    font-weight: 400;
    height: -webkit-fill-available;
    min-height: calc(var(--vh, 1vh) * 100);
    min-height: -webkit-fill-available;
    background-color: ${({ theme }) => theme.colors?.white};
  }

  h1,
  h4 {
    font-weight: 700;
  }
  h1 {
    font-size: clamp(2rem, 1.6479rem + 1.5023vw, 3rem);
    line-height: 105.02%;
    letter-spacing: -0.03em;
  }
  h4 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: ${({ theme }) => theme.colors?.black};
  }
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px ${({ theme }) => theme.colors?.inputBackground} inset;
    box-shadow: 0 0 0 50px ${({ theme }) => theme.colors?.inputBackground} inset;
  }

  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px ${({ theme }) => theme.colors?.inputBackground} inset;
    box-shadow: 0 0 0 50px ${({ theme }) => theme.colors?.inputBackground} inset;
  }
  input,
  select {
    font-size: 100%;
    font-family: 'Poppins', sans-serif;
  }
  textarea {
    font-family: 'Poppins', sans-serif;
  }
  button {
    font-family: 'Gotham', sans-serif;
  }
`;
const BasedStyles = createGlobalStyle`
    ${ResetStyle}
    ${GlobalStyle}
`;

export default BasedStyles;
