import {
  fetchStatistics,
  fetchTotalCount,
  fetchTotalPaid,
  fetchTransactionLevel,
  fetchUserCount
} from '@/api/dashboardApi';
import PageHeader from '@/components/PageHeader';
import PageLoader from '@/loaders/PageLoader';
import { useQuery } from '@tanstack/react-query';
import { styled } from 'styled-components';
import DashboardMetrics from './components/DashboardMetrics';
import withAuthentication from '@/hooks/withAuthentication';
import DashboardCards from './components/DashboardCards';

function Dashboard() {
  const userCount = useQuery({
    queryKey: ['userCount'],
    queryFn: fetchUserCount
  });

  const stats = useQuery({
    queryKey: ['stats'],
    queryFn: fetchStatistics
  });

  const transactionLevel = useQuery({
    queryKey: ['transactionLevel'],
    queryFn: fetchTransactionLevel
  });

  if (userCount.isFetching || stats.isFetching || transactionLevel.isFetching) {
    return <PageLoader />;
  }

  return (
    <>
      <PageHeader title="Dashboard" subtitle="Welcome to Admin dashboard" />
      <DashboardView>
        {/* <DashboardMetrics totalCount={totalCount?.data?.data} totalPaid={totalPaid?.data?.data} /> */}
        <DashboardCards
          stats={stats?.data?.data}
          userCount={userCount?.data?.data}
          transactionLevel={transactionLevel?.data?.data}
        />
      </DashboardView>
    </>
  );
}

export default withAuthentication(Dashboard);

const DashboardView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;
