import { fetchLenderInflows } from '@/api/lendersApi'
import { useSortableData } from '@/hooks/useSortableData';
import TableLoader from '@/loaders/TableLoader';
import { numberWithCommas } from '@/utils/helpers';
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns';
import React from 'react'
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

export const LenderInflows = () => {
  const { id } = useParams();
  
  const inflows = useQuery({
    queryKey: ['inflows', { id }],
    queryFn: fetchLenderInflows
  });

  const { items, requestSort, sortConfig } = useSortableData(inflows?.data?.data || []);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  }

  console.log(inflows);

  if (inflows.isFetching) {
    return <TableLoader />
  }

  return (
    <InflowsTableView>
      <TableHead>
        <div className='provider'>
          <span>Type</span>
        </div>
        <div className='provider'>
          <span>Tenure</span>
        </div>
        {/* <div className='channel'>
          <span>Company</span>
        </div>  */}
        <div className='amount'>
          <span>Loan Amt.</span>
        </div>
        <div className='amount'>
          <span>Interest</span>
        </div>
        <div className='amount'>
          <span>Amt.Paid</span>
        </div>
        <div className='amount'>
          <span>Balance</span>
        </div>
        {/*<div className='remark'>
          <span>Remark</span>
        </div>
        <div className='status'>
          <span>Status</span>
        </div>*/}
        <div className='created'>
          <span>Date created</span>
        </div> 
        {/* <div className='updated'>
          <span>Date updated</span>
        </div> */}
      </TableHead>
      <TableBody>
        {items.map((item) => {
          return (
            <div key={item?.id} className="item">
              <div className='provider'>
                <span>{item?.loan?.type.replace(/_/g, ' ')}</span> 
              </div>
              <div className='provider'>
                <span>{item?.loan?.tenure} {item?.loan?.tenure === 1 ? 'day' : 'days'}</span> 
              </div>
              {/*<div className='channel'>
                {/* <span>{item?.loan?.loanDetails[0]?.businessName || '-'}</span>
              </div>
              */}
               <div className='amount'>
                <span>₦{numberWithCommas(item?.loan?.amount || '00.00')}</span>
              </div>
              <div className='amount'>
                <span>₦{numberWithCommas(item?.loan?.interest || '00.00')}</span>
              </div>
              <div className='amount'>
                <span>₦{numberWithCommas(item?.loan?.paid || '00.00')}</span>
              </div>
              <div className='amount'>
                <span>₦{numberWithCommas(item?.loan?.balance || '00.00')}</span>
              </div>
              {/*<div className='event'>
                <span>{item?.event}</span>
              </div>
              <div className='remark'>
                <span>{item?.remark}</span>
              </div>
              <Status status={item?.status} className='status'>
                  <span className="dot"></span> {item?.status || '-'}
              </Status>*/}
              <div className='created'>
                <span>{format(new Date(item?.loan?.createdAt), 'do MMM, yyy')}</span>
              </div>
              {/*<div className='updated'>
                <span>{format(new Date(item?.updatedAt), 'do MMM, yyy')}</span>
              </div> */}
            </div>
          )
        })}
      </TableBody>
    </InflowsTableView>
  )
}

const getStatusColor = (status) => {
  switch (status) {
    case 'successful':
      return '#16A34A';
    case 'pending':
      return '#F4B740';
    case 'processing':
      return '#F4B740';
    case 'initiated':
      return '#F4B740';
    case 'failed':
      return '#B91C1C';
    case 'declined':
      return '#B91C1C';

    default:
  }
};

const Status = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: ${({ status }) => getStatusColor(status)};

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: ${({ status }) => getStatusColor(status)};
  }
`

const InflowsTableView = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  padding-bottom: 30px;
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      width: 10px;
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors.black};

    .up path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors.black};

    .down path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .channel {
    flex: 0.8;
  }

  .event {
    flex: 0.3;
  }

  .provider {
    flex: 0.5;
  }

  .amount {
    flex: 0.5;
  }

  .remark {
    flex: 1;
  }

  .status {
    flex: 0.5;
  }

  .created {
    flex: 0.5;
  }

  .updated {
    flex: 0.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .channel {
      flex: 0.8;
    }

    .event {
      flex: 0.3;
    }

    .provider {
      flex: 0.5;
    }

    .amount {
      flex: 0.5;
    }

    .remark {
      flex: 1;
    }

    .status {
      flex: 0.5;
    }

    .created {
      flex: 0.5;
    }

    .updated {
      flex: 0.5;
    }

    .actions {
      /* flex: 0.5; */
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
  }
`;