import { getRoles } from '@/api/accountsApi';
import useOnClickOutside from '@/hooks/useClickOutside';
import TableLoader from '@/loaders/TableLoader';
import { useQuery } from '@tanstack/react-query';
import React, { useRef, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import styled from 'styled-components';
import { useSortableData } from '@/hooks/useSortableData';
import { format } from 'date-fns';
import UpdateRoleModal from './UpdateRoleModal';
import DeleteRoleModal from './DeleteRoleModal';
import Button from '@/components/Button';
import { usePermissions } from '@/hooks/usePermissions';
import toast from 'react-hot-toast';
import CreateRoleModal from './CreateRoleModal';

export const Roles = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [ showCreateRole, setShowCreateRole ] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const { hasPermission } = usePermissions();
  const canCreateRole = hasPermission('settings', 'create');

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const roles = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles
  });

  const { items, requestSort, sortConfig } = useSortableData(roles?.data?.data?.roles || []);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handleCreate = () => {
    if(!canCreateRole) {
      toast.error('You do not have permission to create');
      return;
    } else {
      setShowCreateRole(!showCreateRole);
    }
  }

  if (roles.isFetching) {
    return <TableLoader />
  }

  return (
    <TableView>
      <TableHeader>
        <Button
          width="20%"
          height="48px"
          type="button"
          onClick={() => {
            handleCreate();
          }}
        >
          Create Role
        </Button>
      </TableHeader>
      <TableHead>
        <div className="type">
          <span>Name</span>
        </div>
        <div>
          <span>Date Created</span>
        </div>
        <div>
          <span>Date Updated</span>
        </div>
        {/* <div className="amount">
          <span>Invoice Value</span>
        </div>
        <div className="amount">
          <span>Discounting Request</span>
        </div> */}
        <div className="actions"></div> 
      </TableHead>
      <TableBody>
        {items.map((item, i) => {
          return (
            <div key={item?.id} className="item">
              <div className="type">
                <span>{item?.name}</span>
              </div>
              <div>
                <span>{format(new Date(item.createdAt), 'do MMM, yyyy')}</span>
              </div>
              <div>
                <span>{format(new Date(item.updatedAt), 'do MMM, yyyy')}</span>
              </div>
              {/*<div className="amount">
                <span>₦{numberWithCommas(parseInt(item?.bill?.amount))}</span>
              </div>
              <div className="amount">
                <span>₦{numberWithCommas(item?.discountAmount)}</span>
              </div> */}

              <div className="actions">
              <button
                  className="menu-icon"
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                  }}>
                  <MoreIcon />
                </button>

                {/* ===== Dropdown Menu ====== */}
                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      className="menu-actions"
                      ref={ref}
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        onClick={() => {
                          setShowUpdateModal(true);
                          setSelectedItem(item?.id);
                      }}>
                        <span>Edit role</span>
                      </button>
                      <button
                        onClick={() => {
                          setSelectedItem(item?.id);
                          setShowDeleteModal(true);
                        }}>
                        <span>Delete role</span>
                      </button>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            </div>
          );
        })}
      </TableBody>

      <CreateRoleModal 
        showModal={showCreateRole} 
        setShowModal={setShowCreateRole} 
      />

      <UpdateRoleModal 
        showModal={showUpdateModal}
        selectedItem={selectedItem}
        setShowModal={setShowUpdateModal}
      />

      <DeleteRoleModal 
        showModal={showDeleteModal}
        selectedItem={selectedItem}
        setShowModal={setShowDeleteModal}
      />
    </TableView>
  )
}

const TableView = styled.div`
  width: 100%;
  height: 100%;
`;

const TableHeader = styled.div`
  /* width: 100%; */
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.line};
  border-left: none;
  border-top: none;
  border-right: none;
  /* margin-top: 10px; */
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      width: 10px;
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors.black};

    .up path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors.black};

    .down path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .amount {
    flex: 0.8;
  }

  .year {
    flex: 0.8;
  }

  .reg {
    flex: 0.6;
  }

  .tin {
    flex: 0.6;
  }

  .status {
    flex: 0.5;
  }

  .date {
    flex: 0.5;
  }

  .actions {
    flex: 0.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .amount {
      flex: 0.8;
    }

    .year {
      flex: 0.8;
    }

    .reg {
      flex: 0.6;
    }

    .tin {
      flex: 0.6;
    }

    .status {
      flex: 0.5;

      .verified {
        color: ${({ theme }) => theme.colors?.success};
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.75rem;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.success};
          margin-right: 4px;
        }
      }

      .pending {
        color: ${({ theme }) => theme.colors?.warning};
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.75rem;
        text-transform: capitalize;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.warning};
          margin-right: 4px;
        }
      }
    }

    .date {
      flex: 0.5;
    }

    .actions {
      flex: 0.5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
  }
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    button {
      width: 40px;
      height: 40px;
      border: none;
      outline: none;
      background-color: ${({ theme }) => theme.colors?.layer3};
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: ${({ theme }) => theme.colors?.layer2};
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;
