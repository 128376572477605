import api from "./api"

export const fetchTerms = async () => {
  const response = await api.get(`/admin/setting/terms`);
  return response.data;
}

export const fetchTermTypes = async () => {
  const response = await api.get(`/admin/setting/term-types`);
  return response.data;
}

export const fetchTerm = async ({ queryKey }) => {
  const [, type] = queryKey;
  if (type) {
    const response = await api.get(`/admin/setting/terms/${type}`);
    return response.data;
  } else {
    throw new Error('No term type provided');
  }
}

export const createTerm = async (data) => {
  const response = await api.put(`/admin/setting/terms`, data);
  return response.data;
}

export const updateTerm = async (data) => {
  const response = await api.put(`/admin/setting/terms/`, data);
  return response.data;
}