import { styled } from 'styled-components';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { Card } from '@/components/MetricsCard';

function BillsMetrics({ totalBills, totalPaidBills, totalBillAmount, totalPaidBillAmount, totalUnPaidBillCount, totalUnPaidBillAmount, totalPartiallyPaidBill, totalAmountPartiallyPaidBill }) {
  return (
    <>
      <BillsMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(totalBills || '00.00')}</h3>
            <p>Total Bills</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(totalBillAmount || '00.00')}</h3>
            <p>Total Bills Amount</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(totalPaidBills || '00.00')}</h3>
            <p>Paid Bills</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(totalPaidBillAmount || '00.00')}</h3>
            <p>Paid Bills Amount</p>
          </Card>
        </div>
      </BillsMetricsView>

      <BillsMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(totalPartiallyPaidBill || '00.00')}</h3>
            <p>Partially Paid Bills</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(totalAmountPartiallyPaidBill || '00.00')}</h3>
            <p>Partially Paid Bills Amount</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(totalUnPaidBillCount || '00.00')}</h3>
            <p>Unpaid Bills</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(totalUnPaidBillAmount || '00.00')}</h3>
            <p>Unpaid Bills Amount</p>
          </Card>
        </div>
      </BillsMetricsView>
    </>
  );
}

export default BillsMetrics;

const BillsMetricsView = styled.div`
  width: 100%;
  height: 130px;
  border-radius: 6px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  .card-wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.alternateBackground2};
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .grey-text {
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const MetricView = styled.div`
  width: 32%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.secondary};
    word-break: break-all;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({ theme }) => theme.colors.line};
`;
