import { fetchProfile } from '@/api/authApi'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

export const usePermissions = () => {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['profile'],
    queryFn: fetchProfile,
  });

  // Assuming each permission contains a 'Modules' object with a 'slug' property
  const permissions = data?.data?.permissions;

  const hasPermission = (moduleSlug, action) => {
    // Find the permission object where the module's slug matches the provided slug
    const modulePermission = permissions?.find((permission) => permission.Modules.slug === moduleSlug);
    return modulePermission ? !!modulePermission[action] : false;
  };

  // Get the role of the user and check if it matches the provided role
  const role = (role) => {
    const userRole = data?.data?.role;
    return userRole === role;
  }

  return { permissions, hasPermission, role, isLoading, error, refetch };
};
