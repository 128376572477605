import { fetchCompliance, fetchComplianceVerification } from '@/api/complianceApi';
import PageHeader from '@/components/PageHeader';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import { ReactComponent as VerifyIcon } from '@/assets/icons/verify.icon.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.icon.svg';
import { ReactComponent as ExtLinkIcon } from '@/assets/icons/ext-link.icon.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/attachment.icon.svg';
import { ReactComponent as RemarkIcon } from '@/assets/icons/remark.icon.svg';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import ApprovalModal from './components/ApprovalModal';
import RejectModal from './components/RejectModal';
import PageLoader from '@/loaders/PageLoader';
import { toast } from 'react-hot-toast';
import withAuthentication from '@/hooks/withAuthentication';
import { usePermissions } from '@/hooks/usePermissions';

function ComplianceDetails() {
  const navigate = useNavigate();
  const { search } = useLocation();
  // const queryClient = useQueryClient();
  const { id } = useParams();
  const [completedStage, setCompletedStage] = useState(0);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const { hasPermission } = usePermissions();
  const canUpdateCompliance = hasPermission('compliance', 'update');

  const [currentDetailPage, setCurrentDetailPage] = useState(1);

  const params = new URLSearchParams(search);
  const currentPage = params.get('page');

  const handleReturn = () => {
    window.history.back();
    // navigate(`/users?page=${currentPage}`);
  };

  useEffect(() => {
    if (currentPage) {
      setCurrentDetailPage(parseInt(currentPage));
    }
  }, [currentPage]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard');
  };

  const compliance = useQuery({
    queryKey: ['compliance', { id }],
    queryFn: fetchCompliance,
    onSuccess: (data) => {
      const keysToConsider = [
        'businessType',
        'businessYear',
        'certificateUrl',
        'memorandumUrl',
        'proofOfBusinessUrl',
        'registrationNumber',
        'tin',
        'bvn',
        'aboutBusiness'
      ];

      let nonNullCount = 0;

      keysToConsider.forEach((key) => {
        if (data?.data[key] !== null) {
          nonNullCount++;
        }
      });

      const totalKeys = keysToConsider.length;
      const submissionPercentage = Math.round((nonNullCount / totalKeys) * 100);

      setCompletedStage(submissionPercentage);
    }
  });

  const complianceVerification = useQuery({
    queryKey: ['complianceVerification', { id }],
    queryFn: fetchComplianceVerification
  });

  const handleApprove = () => {
    if(!canUpdateCompliance) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setShowApprovalModal(!showApprovalModal);
    }
  }

  const handleReject = () => {
    if(!canUpdateCompliance) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setShowRejectModal(!showRejectModal);
    }
  }

  if (compliance.isFetching || complianceVerification.isFetching) {
    return <PageLoader />;
  }

  return (
    <>
      <PageHeader
        title="Compliance details"
        subtitle="View, approve or decline compliance submissions"
      />
      <ComplianceDetailsView>
        <div className="return" onClick={handleReturn}>
          <BackIcon />
        </div>
        <ComplianceStatus>
          <h3>Verification status</h3>
          <div className="line"></div>
          {compliance?.data?.data?.isVerified ? (
            <div className="status verified">
              <div className="icon">
                <VerifyIcon />
              </div>
              <div className="label-view">
                <span>Approved</span>
              </div>
            </div>
          ) : (
            <div className="status not-verified">
              <div className="icon">
                <VerifyIcon />
              </div>
              <div className="label-view">
                <span>Not verified</span>
              </div>
            </div>
          )}
        </ComplianceStatus>
        <ComplianceProgress>
          <IconView>
            <VerifyIcon />
          </IconView>
          <div className="right-side">
            <div className="progress-title">
              <h3>Submission progress</h3>
              <p>{completedStage}% completed</p>
            </div>
            <ProgressView progress={completedStage}>
              <div className="progress-bar"></div>
            </ProgressView>
          </div>
        </ComplianceProgress>

        <DataView>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>User</h3>
                <p>
                  {compliance?.data?.data?.user?.firstName || '-'}{' '}
                  {compliance?.data?.data?.user?.lastName || '-'}
                </p>
              </div>
              <div className="copy-view">
                <button onClick={() => navigate(`/users/${compliance?.data?.data?.user?.id}`)}>
                  View user <ExtLinkIcon />
                </button>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Business name</h3>
                <p>{compliance?.data?.data?.user?.companies[0]?.name}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Business type</h3>
                <p>{compliance?.data?.data?.businessType}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Business year</h3>
                <p>{compliance?.data?.data?.businessYear}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Registration number</h3>
                <p>{compliance?.data?.data?.registrationNumber}</p>
              </div>
              <div className="copy-view">
                <button onClick={() => copyToClipboard(compliance?.data?.data?.registrationNumber)}>
                  Copy <CopyIcon />
                </button>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Tax ID Number (TIN)</h3>
                <p>{compliance?.data?.data?.tin}</p>
              </div>
              <div className="copy-view">
                <button onClick={() => copyToClipboard(compliance?.data?.data?.tin)}>
                  Copy <CopyIcon />
                </button>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Bank Verification Number (BVN)</h3>
                <p>{compliance?.data?.data?.bvn || '-'}</p>
              </div>
              <div className="copy-view">
                <button onClick={() => copyToClipboard(compliance?.data?.data?.bvn)}>
                  Copy <CopyIcon />
                </button>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Website</h3>
                <p>{compliance?.data?.data?.websiteUrl || '-'}</p>
              </div>
              <div className="copy-view">
                {compliance?.data?.data?.websiteUrl && (
                  <a
                    href={compliance?.data?.data?.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    View <ExtLinkIcon />
                  </a>
                )}
              </div>
            </div>
            <div className="item last">
              <div className="label-view">
                <h3>Business info</h3>
                <p>{compliance?.data?.data?.aboutBusiness || '-'}</p>
              </div>
            </div>
          </FormData>

          <FileView>
            <CTAView>
              <button
                className='decline'
                onClick={() => handleReject()}
              >
                Reject compliance
              </button>
              <button
                className='approve'
                onClick={() => handleApprove()}
              >
                Approve compliance
              </button>
            </CTAView>

            <h3>Attached files</h3>
            <p>Files and assets that have been attached to this submission</p>

            {compliance?.data?.data?.certificateUrl && (
              <FileItem>
                <div className="icon">
                  <FileIcon />
                </div>
                <div className="label-view">
                  <h3>Business registration certificate</h3>
                  <p>
                    {compliance?.data?.data?.certificateUrl.substring(
                      compliance?.data?.data?.certificateUrl.lastIndexOf('/') + 1
                    )}
                  </p>
                </div>
                <div className="view">
                  <a
                    href={compliance?.data?.data?.certificateUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    View <ExtLinkIcon />
                  </a>
                </div>
              </FileItem>
            )}

            {compliance?.data?.data?.memorandumUrl && (
              <FileItem>
                <div className="icon">
                  <FileIcon />
                </div>
                <div className="label-view">
                  <h3>Memorandum of Understanding </h3>
                  <p>
                    {compliance?.data?.data?.memorandumUrl.substring(
                      compliance?.data?.data?.memorandumUrl.lastIndexOf('/') + 1
                    )}
                  </p>
                </div>
                <div className="view">
                  <a
                    href={compliance?.data?.data?.memorandumUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    View <ExtLinkIcon />
                  </a>
                </div>
              </FileItem>
            )}

            {compliance?.data?.data?.proofOfBusinessUrl && (
              <FileItem>
                <div className="icon">
                  <FileIcon />
                </div>
                <div className="label-view">
                  <h3>Utility bill</h3>
                  <p>
                    {compliance?.data?.data?.proofOfBusinessUrl.substring(
                      compliance?.data?.data?.proofOfBusinessUrl.lastIndexOf('/') + 1
                    )}
                  </p>
                </div>
                <div className="view">
                  <a
                    href={compliance?.data?.data?.proofOfBusinessUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    View <ExtLinkIcon />
                  </a>
                </div>
              </FileItem>
            )}

            <h3 className="verification">Verification report</h3>
            <p className="sub-text">
              Compliance verification report. This report is generated after a compliance has been
              verified
            </p>

            <ComplianceStatus>
              <h3>Compliance status</h3>
              <div className="line"></div>
              {compliance?.data?.data?.verifications[0]?.isValid ? (
                <div className="status verified">
                  <div className="icon">
                    <VerifyIcon />
                  </div>
                  <div className="label-view">
                    <span>Valid</span>
                  </div>
                </div>
              ) : (
                <div className="status not-verified">
                  <div className="icon">
                    <VerifyIcon />
                  </div>
                  <div className="label-view">
                    <span>Invalid</span>
                  </div>
                </div>
              )}
            </ComplianceStatus>

            {compliance?.data?.data?.verifications?.length > 0 && (
              <>
                {compliance?.data?.data?.verifications[0]?.reportDocUrl ? (
                  <FileItem>
                    <div className="icon">
                      <FileIcon />
                    </div>
                    <div className="label-view">
                      <h3>Compliance report</h3>
                      <p>
                        {compliance?.data?.data?.verifications[0]?.reportDocUrl.substring(
                          compliance?.data?.data?.verifications[0]?.reportDocUrl.lastIndexOf('/') +
                            1
                        )}
                      </p>
                    </div>
                    <div className="view">
                      <a
                        href={compliance?.data?.data?.verifications[0]?.reportDocUrl}
                        target="_blank"
                        rel="noopener noreferrer">
                        View <ExtLinkIcon />
                      </a>
                    </div>
                  </FileItem>
                ) : null}

                {compliance?.data?.data?.verifications[0]?.remarks ? (
                  <FileRemark>
                    <div className="icon">
                      <RemarkIcon />
                    </div>
                    <div className="label-view">
                      <h3>Remark</h3>
                      <p>{compliance?.data?.data?.verifications[0]?.remarks}</p>
                    </div>
                  </FileRemark>
                ) : null}
              </>
            )}
          </FileView>
        </DataView>
      </ComplianceDetailsView>
      <ApprovalModal
        showModal={showApprovalModal}
        setShowModal={setShowApprovalModal}
        complianceId={id}
      />
      <RejectModal
        showModal={showRejectModal}
        setShowModal={setShowRejectModal}
        complianceId={id}
      />
    </>
  );
}

export default withAuthentication(ComplianceDetails);

const ComplianceDetailsView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 110px;
  overflow-x: hidden;
  margin-left: 18%;

  .return {
    width: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .progress-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    h3 {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }
`;

const ProgressView = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${({ theme }) => theme.colors?.secondary};
  border-radius: 6px;

  .progress-bar {
    width: ${({ progress }) => `${progress}%`};
    height: 10px;
    background-color: ${({ theme }) => theme.colors?.primary};
    border-radius: 6px;
  }
`;

const ComplianceProgress = styled.div`
  width: 100%;
  margin-bottom: 30px;
  border-radius: 6px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors?.white};
  border: 1px solid ${({ theme }) => theme.colors?.line};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right-side {
    width: calc(100% - 70px);
  }
`;

const IconView = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background-color: #f3f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
`;

const FormData = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ theme }) => theme?.colors?.secondary};
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const FileView = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.colors?.white};
  padding-left: 20px;

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme?.colors?.secondary};
    margin-bottom: 8px;
  }

  .verification {
    margin-top: 40px;
  }

  p {
    font-size: 0.875rem;
    font-weight: 500;
    color: ${({ theme }) => theme?.colors?.secondary};
  }

  .sub-text {
    margin-bottom: 20px;
  }
`;

const FileItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f3f2f2;
  border-radius: 6px;
  margin-top: 20px;

  .icon {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors?.white};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .label-view {
    width: calc(100% - 190px);

    h3 {
      font-size: 0.875rem;
      font-weight: 600;
      color: ${({ theme }) => theme?.colors?.secondary};
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }

  .view {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      color: ${({ theme }) => theme?.colors?.info};
      font-size: 0.875rem;
      display: flex;
      align-items: center;

      svg {
        margin-left: 5px;
        width: 16px;
        height: 16px;

        path {
          stroke: ${({ theme }) => theme?.colors?.info};
        }
      }
    }
  }
`;

const FileRemark = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  background-color: #f3f2f2;
  border-radius: 6px;
  margin-top: 20px;

  .icon {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors?.white};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .label-view {
    width: calc(100% - 60px);

    h3 {
      font-size: 0.875rem;
      font-weight: 600;
      color: ${({ theme }) => theme?.colors?.secondary};
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }
`;

const CTAView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;

  .approve {
    width: 200px;
    height: 50px;
    background-color: ${({ theme }) => theme.colors?.primary};
    color: ${({ theme }) => theme.colors?.white};
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }

  .decline {
    width: 160px;
    height: 50px;
    background-color: transparent;
    color: ${({ theme }) => theme.colors?.error};
    border: 1px solid ${({ theme }) => theme.colors?.error};
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }

  .disabled-button {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ComplianceStatus = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme?.colors?.secondary};
  }

  .line {
    width: 1px;
    height: 20px;
    background-color: ${({ theme }) => theme?.colors?.line};
    margin: 0 20px;
  }

  .status {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      margin-right: 5px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .label-view {
      span {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }

  .not-verified {
    background-color: ${({ theme }) => theme?.colors?.warningBackground};

    .icon {
      svg {
        path {
          stroke: ${({ theme }) => theme?.colors?.warning};
        }
      }
    }

    .label-view {
      span {
        color: ${({ theme }) => theme?.colors?.warning};
      }
    }
  }

  .verified {
    background-color: ${({ theme }) => theme?.colors?.successBackground};

    .icon {
      svg {
        path {
          stroke: ${({ theme }) => theme?.colors?.success};
        }
      }
    }

    .label-view {
      span {
        color: ${({ theme }) => theme?.colors?.success};
      }
    }
  }
`;
