import { fetchLedgerMetrics } from "@/api/walletApi";
import { numberWithCommas, numberWithCommas2 } from "@/utils/helpers";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { Card } from "@/components/MetricsCard";
import { Oval } from "react-loader-spinner";

const CreditHistoryMetrics = () => {
  const metrics = useQuery({
    queryKey: ['metrics', { event: 'credit' }],
    queryFn: fetchLedgerMetrics
  })

  const metric = metrics?.data?.data;

  if (metrics?.isFetching) {
    return (
      <LoaderView>
        <div className="loading-view">
          <Oval color="#92C22C" width={50} height={50} strokeWidth={5} />
        </div>
      </LoaderView>
    );
  }

  return (
    <>
      <CreditHistoryMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(metric?.totalCategoryEntries || '0')}</h3>
            <p>Total Category Entries</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(metric?.totalCategoryAmount || '00.00')}</h3>
            <p>Total Category Amount</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.totalSuccessfulWalletsTransactions || '0')}</h3>
            <p>Total Successful Transactions</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(metric?.totalSuccessfulWalletsTransactionsAmount || '00.00')}</h3>
            <p>Total Successful Transactions Amount</p>
          </Card>
        </div>
      </CreditHistoryMetricsView>

      <CreditHistoryMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(metric?.totalPendingWalletsTransactions || '0')}</h3>
            <p>Total Pending Transactions</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(metric?.totalPendingWalletsTransactionsAmount || '00.00')}</h3>
            <p>Total Pending Transactions Amount</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.totalFailedWalletsTransactions || '0')}</h3>
            <p>Total Failed Transactions</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(metric?.totalFailedWalletsTransactionsAmount || '00.00')}</h3>
            <p>Total Failed Transactions Amount</p>
          </Card>
        </div>
      </CreditHistoryMetricsView>
    </>
  )
}

export default CreditHistoryMetrics;

const CreditHistoryMetricsView = styled.div`
  width: 100%;
  height: 130px;
  margin-bottom: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;

  .card-wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.alternateBackground2};
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .grey-text {
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const MetricView = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({ theme }) => theme.colors.line};
`;

const LoaderView = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .loading-view {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;