import api from './api';

export const fetchUsers = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `/admin/users?page=${page}&limit=${limit}`;
  
  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
};

export const fetchUser = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/users/${id}`);
  return response.data;
};

export const fetchUserData = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/users/${id}/data`);
  return response.data;
};

export const assignUserToPlan = async (data) => {
  const response = await api.post(`/admin/subscription/users/list`, data);
  return response.data;
};

export const assignMultipleUsersToPlan = async (data) => {
  const response = await api.post(`/admin/subscription/users/list/bulk`, data);
  return response.data;
};

export const fetchUsersStats = async ({ queryKey }) => {
  const [, { fetch }] = queryKey;
  const response = await api.get(`/admin/users/metrics/stat?fetch=${fetch}`);
  return response.data;
}

export const exportUser = async (data) => {
  const response = await api.post(`/admin/export/user`, data);
  return response.data;
}

export const deactivateUser = async (data) => {
  const response = await api.post(`/admin/users/action/suspend`, data);
  return response.data;
}

export const activateUser = async (data) => {
  const response = await api.post(`/admin/users/action/activate`, data);
  return response.data;
}

export const fetchUserActivity = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/users/activities/${id}`);
  return response.data;
}
export const fetchUserTransactionActivity = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/users/transaction/activities/${id}`);
  return response.data;
}
export const fetchUserTimeline = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/users/timeline/${id}`);
  return response.data;
}