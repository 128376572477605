import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { styled } from 'styled-components';
import PropTypes from 'prop-types';

function DashboardMetrics({ totalCount, totalPaid }) {
  return (
    <DashboardMetricsView>
      <MetricView>
        <h3>{numberWithCommas2(totalCount?.payables || '00.00')}</h3>
        <p>Payables</p>
      </MetricView>
      <Divider></Divider>
      <MetricView>
        <h3>{numberWithCommas2(totalCount?.receivables || '00.00')}</h3>
        <p>Receivables</p>
      </MetricView>
      <Divider></Divider>
      <MetricView>
        <h3>₦{numberWithCommas(totalPaid?.totalPayables?.totalPaid || '00.00')}</h3>
        <p>Total payables</p>
      </MetricView>
      <Divider></Divider>
      <MetricView>
        <h3>₦{numberWithCommas(totalPaid?.totalReceivables?.totalPaid || '00.00')}</h3>
        <p>Total receivables</p>
      </MetricView>
    </DashboardMetricsView>
  );
}

export default DashboardMetrics;

DashboardMetrics.propTypes = {
  totalPaid: PropTypes.object.isRequired,
  totalCount: PropTypes.object.isRequired
};

const DashboardMetricsView = styled.div`
  width: 100%;
  height: 130px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.line};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MetricView = styled.div`
  width: 32%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({ theme }) => theme.colors.line};
`;
