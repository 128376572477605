import { numberWithCommas2 } from '@/utils/helpers';
import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import { Card } from '@/components/MetricsCard';

function UserMetrics({ userData }) {
  return (
    <>
      <UserMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(userData?.totalBills || '00.00')}</h3>
            <p>Total bills</p>
            <p className='grey-text'>(Ready + Partially paid bills)</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(userData?.pendingInvoices || '00.00')}</h3>
            <p>Pending invoices</p>
            <p className='grey-text'>(Sent + Partially paid invoices)</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas2(userData?.inflows || '00.00')}</h3>
            <p>Total inflows</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas2(userData?.outflows || '00.00')}</h3>
            <p>Total outflows</p>
          </Card>
        </div>
      </UserMetricsView>

      <UserMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{userData?.subscription?.subscriptionPlan?.name || '-'}</h3>
            <p>Current subscription plan</p>
          </Card>
        </div>
      </UserMetricsView>
    </>
  );
}

export default UserMetrics;

UserMetrics.propTypes = {
  userData: PropTypes.object.isRequired
};

const UserMetricsView = styled.div`
  width: 100%;
  height: 130px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .card-wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.alternateBackground2};
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .grey-text {
      margin-top: -1rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const MetricView = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }

  .grey-text {
      font-size: 0.7rem;
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.colors.grey};
    }
`;

const Divider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({ theme }) => theme.colors.line};
`;
