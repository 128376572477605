// Desc: HOC to check if user is authenticated

import { Navigate } from 'react-router-dom';
import useAuthentication from './useAuthentication';

const withAuthentication = (Component) => (props) => {
  const { authenticated } = useAuthentication();

  if (authenticated) {
    return <Component {...props} />;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default withAuthentication;
