import React, { useMemo } from "react";

export const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      if (sortConfig.key === "user") {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key]?.fullName < b[sortConfig.key]?.fullName) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key]?.fullName > b[sortConfig.key]?.fullName) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      } else if (sortConfig.key === "customer") {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key]?.name < b[sortConfig.key]?.name) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key]?.name > b[sortConfig.key]?.name) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      } else {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
