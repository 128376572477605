import { Oval } from 'react-loader-spinner'
import styled from 'styled-components'

function TableLoader() {
  return (
    <LoaderView>
      <Oval color="#92C22C" width={50} height={50} strokeWidth={5} />
    </LoaderView>
  )
}

export default TableLoader

const LoaderView = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 120px;
  overflow-x: hidden;
  overflow: hidden;
  display: flex;
  justify-content: center;
`
