import { fetchTransaction } from '@/api/transactionApi';
import { useQuery } from '@tanstack/react-query';

import PageHeader from '@/components/PageHeader';
import styled from 'styled-components';
import PageLoader from '@/loaders/PageLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ExtLinkIcon } from '@/assets/icons/ext-link.icon.svg';
import { numberWithCommas } from '@/utils/helpers';
import { format } from 'date-fns';
import { fetchProfile } from '@/api/authApi';
import TransactionModal from './components/TransactionReconciliationModal';
import { useState } from 'react';
import Button from '@/components/Button';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';

const TransactionDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showTransactionModal, setShowTransactionModal] = useState(false);

  const transaction = useQuery({
    queryKey: ['transaction', { id }],
    queryFn: fetchTransaction,
    onSuccess: (data) => {}
  });

  const info = transaction?.data?.data;

  const profile = useQuery({
    queryKey: ['profile'],
    queryFn: fetchProfile
  });

  const profileInfo = profile?.data?.data;
  
  const handleReturn = () => {
    window.history.back();
  };

  if (transaction.isFetching) {
    return <PageLoader />;
  }

  return (
    <>
      <PageHeader title="Transaction Details" subtitle="View and manage Transactions" />
      <TransactionsDetailsView>
          <div className="btn">
            <div className="return" onClick={handleReturn}>
              <BackIcon />
            </div>

            {profileInfo?.isSuper === true && (
              <Button
                width="20%"
                height="48px"
                type="button"
                onClick={() => {
                  setShowTransactionModal(true);
                }}
              >
                Reconciliate
              </Button>
            )}
          </div>
        <DataView>
          <div className="col2">
            <FormData>
              <div className="head-title">
                <h3>Transaction information</h3>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Transaction Status</h3>
                  <Status status={info?.status}>{info?.status || '--'}</Status>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Transaction Id</h3>
                  <p>{info?.id || '--'}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Bill Id</h3>
                  <p>{info?.billId}</p>
                </div>
                <div className="copy-view">
                  <button
                    onClick={() => {
                      navigate(`/bills/${info?.billId}`);
                    }}>
                    View <ExtLinkIcon />
                  </button>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Reference No.</h3>
                  <p>{info?.reference || '--'}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Remark</h3>
                  <p>{info?.remark || '--'}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Amount</h3>
                  <p>₦{numberWithCommas(info?.amount || '00.00')}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Payment Method</h3>
                  <p>{info?.paymentMethod}</p>
                </div>
              </div>
              {/* <div className="item">
                <div className="label-view">
                  <h3>Payment Date</h3>
                  <p>{format(new Date(info?.paidAt), 'do MMM, yyyy, h:mm aa') || '--'}</p>
                </div>
              </div> */}
              <div className="item">
                <div className="label-view">
                  <h3>Date Created</h3>
                  <p>{format(new Date(info?.createdAt), 'do MMM, yyyy, h:mm aa') || '--'}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Date Updated</h3>
                  <p>{format(new Date(info?.updatedAt), 'do MMM, yyyy, h:mm aa') || '--'}</p>
                </div>
              </div>
            </FormData>
          </div>

          <div className="col2">
            <FormData>
              <div className="head-title">
                <h3>Sender's information</h3>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>User Id</h3>
                  <p>{info?.userId}</p>
                </div>
                <div className="copy-view">
                  <button
                    onClick={() => {
                      navigate(`/users/${info?.userId}`);
                    }}>
                    View <ExtLinkIcon />
                  </button>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Fullname</h3>
                  <p>{`${info?.user?.firstName} ${info?.user?.lastName}`}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Email Address</h3>
                  <p>{info?.user?.email}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Account Number</h3>
                  <p>{info?.user?.virtualAccounts[0]?.accountNumber}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Phone No.</h3>
                  <p>{info?.user?.phoneNumber}</p>
                </div>
              </div>
            </FormData>

            {info?.bill?.customer === null ? (
               <FormData>
               <div className="head-title">
                 <h3>Recipient's information</h3>
               </div>
               <div className="item">
                 <div className="label-view">
                   <h3>Fullname</h3>
                   <p>{info?.bill?.vendor?.name}</p>
                 </div>
               </div>
               <div className="item">
                 <div className="label-view">
                   <h3>Email Address</h3>
                   <p>{info?.bill?.vendor?.email}</p>
                 </div>
               </div>
               <div className="item">
                 <div className="label-view">
                   <h3>Phone No.</h3>
                   <p>{info?.bill?.vendor?.phoneNumber}</p>
                 </div>
               </div>
             </FormData>
            ) : (
            <FormData>
              <div className="head-title">
                <h3>Recipient's information</h3>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Fullname</h3>
                  <p>{info?.bill?.customer?.name}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Email Address</h3>
                  <p>{info?.bill?.customer?.email}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Phone No.</h3>
                  <p>{info?.bill?.customer?.phoneNumber}</p>
                </div>
              </div>
            </FormData>
          )}
          </div>
        </DataView>
      </TransactionsDetailsView>
      <TransactionModal
        showModal={showTransactionModal}
        setShowModal={setShowTransactionModal}
        item={info}
      />
    </>
  );
};

export default TransactionDetails;

const Status = styled.p`
  color: ${({ status }) => {
    switch (status) {
      case 'successful':
        return '#16A34A';
      case 'pending':
        return '#F4B740';
      case 'processing':
        return '#F4B740';
      case 'initiated':
        return '#F4B740';
      case 'pending':
        return '#F4B740';
      case 'failed':
        return '#B91C1C';
      case 'declined':
        return '#B91C1C';

      default:
    }
  }};
  text-transform: capitalize;
`;

const TransactionsDetailsView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 100px;
  overflow-x: hidden;
  margin-left: 18%;

  
  .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;

    .return {
      width: 30px;
      cursor: pointer;
    }
  }
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  .col2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const FormData = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .head-title {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
    padding-bottom: 20px;

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: ${({ theme }) => theme?.colors?.secondary};

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;
