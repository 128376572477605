import { fetchUser, fetchUserData } from '@/api/userApi';
import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.icon.svg';
import { ReactComponent as ExtLinkIcon } from '@/assets/icons/ext-link.icon.svg';
import { format } from 'date-fns';
import TableLoader from '@/loaders/TableLoader';
import PageLoader from '@/loaders/PageLoader';

export const UserInformation = () => {
  const { id } = useParams();
  const user = useQuery({
    queryKey: ['user', { id }],
    queryFn: fetchUser
  });

  const userData = useQuery({
    queryKey: ['userData', { id }],
    queryFn: fetchUserData
  });

  const detail = user?.data?.data;

  if (user.isFetching || userData.isFetching) {
    return <PageLoader />;
  }

  return (
    <UserInfoView>
      <DataView>
          <div className="col2">
            <FormData>
              <div className="head-title">
                <h3>Personal information</h3>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>User Status</h3>
                  <Status status={detail?.status}>{(detail?.status || '--').toLowerCase().replace(/^\w/, c => c.toUpperCase())}</Status>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Name</h3>
                  <p>
                    {detail?.firstName || '-'} {detail?.lastName || '-'}
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Email</h3>
                  <p>{detail?.email}</p>
                </div>
                <div className="copy-view">
                  <a
                    href={`mailto:${detail?.email}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Email user <ExtLinkIcon />
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Referred from</h3>
                  <p className="capitalize">{detail?.signupReferringPlatform || '-'}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Phone</h3>
                  <p>{detail?.phoneNumber || '-'}</p>
                </div>
                <div className="copy-view">
                  <button onClick={() => copyToClipboard(detail?.phoneNumber)}>
                    Copy <CopyIcon />
                  </button>
                </div>
              </div>
              <div className="item last">
                <div className="label-view">
                  <h3>Date joined</h3>
                  {detail?.createdAt && (
                    <p>{format(new Date(detail?.createdAt), 'do MMM, yyyy')}</p>
                  )}
                </div>
              </div>
            </FormData>

            <FormData>
              <div className="head-title">
                <h3>Onboarding information</h3>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Account Type</h3>
                  <p>{detail?.accountType?.name || '-'}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Accounting Software</h3>
                  <p>{detail?.companies[0]?.accountingSoftware || '-'}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Address</h3>
                  <p>{detail?.companies[0]?.address || '-'}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Monthly Bill Payment Volume</h3>
                  <p>{detail?.companies[0]?.monthlyBillPaymentVolume || '-'}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Monthly Bill Volume</h3>
                  <p>{detail?.companies[0]?.monthlyBillVolume || '-'}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Monthly Bill Volume</h3>
                  <p>{detail?.companies[0]?.monthlyInvoiceVolume || '-'}</p>
                </div>
              </div>
            </FormData>
          </div>

          <div className="col2">
            <FormData2>
              <div className="head-title">
                <h3>Company information</h3>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Company</h3>
                  <p>{userData?.data?.data?.company || '-'}</p>
                </div>
              </div>
              <div className="item last">
                <div className="label-view">
                  <h3>Company type</h3>
                  <p>{detail?.accountType?.name || '-'}</p>
                </div>
              </div>
            </FormData2>

            <FormData2>
              <div className="head-title">
                <h3>Subscription information</h3>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Plan</h3>
                  {detail?.userSubscription ? (
                    <p>{detail?.userSubscription[0]?.subscriptionPlan?.name || '-'}</p>
                  ) : (
                    <p>{detail?.subscription?.subscriptionPlan?.name || '-'}</p>
                  )}
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Duration</h3>
                  {detail?.userSubscription ? (
                    <p>{detail?.userSubscription[0]?.duration || '-'}</p>
                  ) : (
                    <p>{detail?.subscription?.duration || '-'}</p>
                  )}
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Start date</h3>
                  {detail?.userSubscription ? (
                    <p>
                      {detail?.userSubscription[0]?.startDate
                        ? format(
                            new Date(detail?.userSubscription[0].startDate),
                            'do MMM, yyyy'
                          )
                        : '-'}
                    </p>
                  ) : (
                    <p>
                      {detail?.subscription?.startDate
                        ? format(new Date(detail?.subscription.startDate), 'do MMM, yyyy')
                        : '-'}
                    </p>
                  )}
                </div>
              </div>
              <div className="item last">
                <div className="label-view">
                  <h3>End date</h3>
                  {detail?.userSubscription ? (
                    <p>
                      {detail?.userSubscription[0]?.endDate
                        ? format(
                            new Date(detail?.userSubscription[0].endDate),
                            'do MMM, yyyy'
                          )
                        : '-'}
                    </p>
                  ) : (
                    <p>
                      {detail?.subscription?.endDate
                        ? format(new Date(detail?.subscription.endDate), 'do MMM, yyyy')
                        : '-'}
                    </p>
                  )}
                </div>
              </div>
            </FormData2>
          </div>
        </DataView>
    </UserInfoView>
  )
}

const UserInfoView = styled.div`
  width: 100%;
  height: 100%;
`;

const Status = styled.p`
  color: ${({ status }) => {
    switch (status) {
      case 'ACTIVE':
        return '#16A34A';
      case 'SUSPENDED':
        return '#B91C1C';

      default:
    }
  }};
  text-transform: capitalize;
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  .col2 {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* justify-content: space-between; */
  }
`;

const FormData = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .head-title {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
    padding-bottom: 20px;

    h3 {
      font-size: 1.2rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      .capitalize {
        text-transform: capitalize;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
        /* color: ${({ theme }) => theme?.colors?.activeTitle}; */
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const FormData2 = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};
  /* margin-bottom: 20px; */

  .head-title {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
    padding-bottom: 20px;

    h3 {
      font-size: 1.2rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      .capitalize {
        text-transform: capitalize;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ theme }) => theme?.colors?.activeTitle};
        text-transform: capitalize;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;