import PageHeader from '@/components/PageHeader'
import React from 'react'
import styled from 'styled-components';
import DetailsTransactions from './DetailsTransactions';

const Details = () => {

  return (
    <>
      <PageHeader title='Leaderboard Details' subtitle='View and manage details' />
      <DetailsView>
        <DetailsTransactions />
      </DetailsView>
    </>
  )
}

export default Details

const DetailsView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;

const getStatusColor = (status) => {
  switch (status) {
    case 'successful':
      return '#16A34A';
    case 'pending':
      return '#F4B740';
    case 'processing':
      return '#F4B740';
    case 'initiated':
      return '#F4B740';
    case 'failed':
      return '#B91C1C';
    case 'declined':
      return '#B91C1C';

    default:
  }
};

const Status = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: ${({ status }) => getStatusColor(status)};

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: ${({ status }) => getStatusColor(status)};
  }
`;

const TableHeader = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};
  border-left: none;
  border-right: none;
  margin-top: 0.75rem;
`;

const SearchView = styled.div`
  width: 40%;
  height: 40px;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    margin-left: 10px;
    font-weight: 400;

    &::placeholder {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.placeholder};
    }
  }
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      width: 10px;
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors.black};

    .up path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors.black};

    .down path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .channel {
    flex: 0.4;
  }

  .event {
    flex: 0.3;
  }

  .provider {
    flex: 0.3;
  }

  .amount {
    flex: 0.5;
  }

  .remark {
    flex: 1;
  }

  .status {
    flex: 0.5;
  }

  .created {
    flex: 0.5;
  }

  .updated {
    flex: 0.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .channel {
      flex: 0.4;
    }

    .event {
      flex: 0.3;
    }

    .provider {
      flex: 0.3;
    }

    .amount {
      flex: 0.5;
    }

    .remark {
      flex: 1;
    }

    .status {
      flex: 0.5;
    }

    .created {
      flex: 0.5;
    }

    .updated {
      flex: 0.5;
    }

    .actions {
      /* flex: 0.5; */
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
  }
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    padding: 2rem 0;

    button {
      width: 40px;
      height: 40px;
      border: none;
      outline: none;
      background-color: ${({ theme }) => theme.colors?.layer3};
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: ${({ theme }) => theme.colors?.layer2};
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;
