import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow-tr.icon.svg';
import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import { ReactComponent as ArrowUpIcon } from '@/assets/icons/arrow-up.icon.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.icon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import { ReactComponent as NextIcon } from '@/assets/icons/nav-right.icon.svg';
import { ReactComponent as PrevIcon } from '@/assets/icons/nav-left.icon.svg';
import useOnClickOutside from "@/hooks/useClickOutside";
import { format } from "date-fns";
import { useMutation, useQuery } from "@tanstack/react-query";
import useDebounce from "@/hooks/useDebounce";
import { motion, AnimatePresence } from "framer-motion";
import { exportWallet, fetchWallets, fetchWalletState } from "@/api/walletApi";
import TableLoader from "@/loaders/TableLoader";
import { useSortableData } from "@/hooks/useSortableData";
import { numberWithCommas } from "@/utils/helpers";
import { Oval } from "react-loader-spinner";
import WalletMetrics from "./metrics/WalletMetrics";
import toast from "react-hot-toast";
import WalletReconciliationModal from "../../users/components/WalletReconciliationModal";
import { WalletStateTabs } from "./tabs/WalletStateTabs";


const WalletsTable = () => {

  return (
    <>
      <WalletsTableView>
        <WalletMetrics />
        <WalletStateTabs />
      </WalletsTableView>
    </>
  )
}

export default WalletsTable

const WalletsTableView = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 80px;
`;