import useOnClickOutside from '@/hooks/useClickOutside';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import Button from '@/components/Button';
import { AnimatePresence, motion } from 'framer-motion';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteSubscription, fetchSubscriptions } from '@/api/subscriptionApi';
import TableLoader from '@/loaders/TableLoader';
import toast from 'react-hot-toast';
import PromptModal from '@/components/PromptModal';
import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import { Oval } from 'react-loader-spinner';
import { usePermission } from 'react-use';
import { usePermissions } from '@/hooks/usePermissions';

const SubscriptionList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showMenu, setShowMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const { hasPermission } = usePermissions();
  const canCreateSubscriptions = hasPermission('subscription', 'create');
  const canUpdateSubscriptions = hasPermission('subscription', 'update');
  const canDeleteSubscriptions = hasPermission('subscription', 'delete');

  const subscriptions = useQuery({
    queryKey: ['subscriptions'],
    queryFn: fetchSubscriptions,
    keepPreviousData: true
  });

  const handleDeleteSubscription = useMutation({
    mutationFn: ({ id }) => deleteSubscription(id),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['subscriptions']);
      setShowDeleteModal(false);
      toast.success('Subscription plan deleted successfully');
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message);
    }
  });

  const handleCreate = () => {
    if(!canCreateSubscriptions) {
      toast.error('You do not have permission to create');
      return;
    } else {
      navigate('/subscriptions/create');
    }
  }

  const handleUpdate = (obj, id) => {
    if(!canUpdateSubscriptions) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setSelectedSubscription(obj);
      navigate(`/subscriptions/${id}`);
    }
  }

  const handleDelete = (obj) => {
    if(!canDeleteSubscriptions) {
      toast.error('You do not have permission to delete');
      return;
    } else {
      setShowDeleteModal(true);
      setSelectedSubscription(obj);
    }
  }


  if (subscriptions.isFetching) {
    return <TableLoader />;
  }

  const subscription = subscriptions?.data?.data;

  return (
    <SubscriptionListView>
      <TableHeader>
        <Button
          width="22%"
          height="48px"
          type="button"
          onClick={() => {
            handleCreate();
          }}>
          Create Subscription
        </Button>
      </TableHeader>
      <TableHead>
        <div className="plan">
          <span>Subscription Plan</span>
        </div>
        <div>
          <span>Monthly</span>
        </div>
        <div>
          <span>Quarterly</span>
        </div>
        <div>
          <span>Annually</span>
        </div>
        <div>
          <span>Status</span>
        </div>
        <div></div>
      </TableHead>
      <TableBody>
        {subscription?.plans?.map((item, i) => {
          return (
            <div key={item?.id} className="items">
              <div className="plan">
                <span>{item?.name}</span>
              </div>
              <div>
                {/* <span>₦{numberWithCommas(item.monthlyCost)}</span> */}
                <span>
                  {item.data.is_unlimited === false
                    ? `₦${numberWithCommas(item.monthlyCost)}`
                    : 'Contact us'}
                </span>
              </div>
              <div>
                {/* <span>₦{numberWithCommas(item.quarterlyCost)}</span> */}
                <span>
                  {item.data.is_unlimited === false
                    ? `₦${numberWithCommas(item.quarterlyCost)}`
                    : 'Contact us'}
                </span>
              </div>
              <div>
                {/* <span>₦{numberWithCommas(item.annualCost)}</span> */}
                <span>
                  {item.data.is_unlimited === false
                    ? `₦${numberWithCommas(item.annualCost)}`
                    : 'Contact us'}
                </span>
              </div>
              <div className="status">
                <Status status={item?.status}>
                  <span className="dot"></span> {item?.status || '-'}
                </Status>
              </div>
              <div className="actions">
                <button
                  className="menu-icon"
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                  }}>
                  <MoreIcon />
                </button>

                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      ref={ref}
                      className="menu-actions"
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        onClick={() => {
                          handleUpdate(item, item?.id);
                        }}>
                        <span>Edit Subscription</span>
                      </button>
                      <button
                        onClick={() => {
                          handleDelete(item);
                        }}>
                        <span className="delete">Delete Subscription</span>
                      </button>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            </div>
          );
        })}
      </TableBody>

      <PromptModal showModal={showDeleteModal}>
        <div className="header">
          <button type="button" onClick={() => setShowDeleteModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon />
          <h3>Delete Subscription</h3>
          <p>Do you really want to delete this subscription?</p>
          <button
            type="button"
            className="delete"
            onClick={() => handleDeleteSubscription.mutate({ id: selectedSubscription?.id })}>
            {handleDeleteSubscription.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </PromptModal>
    </SubscriptionListView>
  );
};
export default SubscriptionList;

const getStatusColor = (status) => {
  switch (status) {
    case 'active':
      return '#16A34A';
    case 'pending':
      return '#F4B740';
    case 'processing':
      return '#F4B740';
    case 'inactive':
      return '#B91C1C';

    default:
  }
};

const Status = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: ${({ status }) => getStatusColor(status)};

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: ${({ status }) => getStatusColor(status)};
  }
`;

const SubscriptionListView = styled.div`
  width: 100%;
  height: 100%;
`;

const TableHeader = styled.div`
  /* width: 100%; */
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.line};
  border-left: none;
  border-top: none;
  border-right: none;
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }
  }

  .plan {
    flex: 1.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .plan {
      flex: 1.5;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }

          .delete {
            color: ${({ theme }) => theme.colors?.error_200};
          }
        }
      }
    }

    /* div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    } */
  }
`;
