import styled from 'styled-components';
import { Card } from './InvoiceDiscountCard';
import InvoiceDiscountTable from './InvoiceDiscountTable';
import { useQuery } from '@tanstack/react-query';
import { fetchInvoiceDiscountMetrics } from '@/api/invoiceDiscounting';
import { Oval } from 'react-loader-spinner';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import useOnClickOutside from '@/hooks/useClickOutside';

const InvoiceDiscountCards = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDateValue, setStartDateValue] = useState('');
  const [endDateValue, setEndDateValue] = useState('');
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const filterRef = useRef();
  useOnClickOutside(filterRef, () => setShowFilterMenu(false));

  const metrics = useQuery({
    queryKey: ['metrics', { startDate: startDate, endDate: endDate }],
    queryFn: fetchInvoiceDiscountMetrics
  });

  const handleFilter = () => {
    setStartDate(startDateValue);
    setEndDate(endDateValue);
    setEndDateValue('');
    setStartDateValue('');
    setShowFilterMenu(false);
  }

  const metric = metrics?.data?.data;

  if (metrics.isFetching) {
    return (
      <LoaderView>
        <div className="loading-view">
          <Oval color="#92C22C" width={50} height={50} strokeWidth={5} />
        </div>
      </LoaderView>
    )
  }

  return (
    <>
      <Filter className="filter">
        <button className="filter-btn" onClick={() => setShowFilterMenu(true)}>
          <FilterIcon /> Filter Metrics
        </button>

        {/* ===== Filter Menu ====== */}
        <AnimatePresence>
          {showFilterMenu ? (
            <motion.div
              className="export-menu"
              ref={filterRef}
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}>
              <div className="heading">
                <span>Filter Metrics</span>
              </div>
              <div className="body">
                <div className="item">
                  <div className="date-input">
                    <label>From:</label>
                    <input
                      type="date"
                      name="startDate"
                      value={startDateValue}
                      onChange={(e) => setStartDateValue(e.target.value)}
                    />
                  </div>
                  <div className="date-input">
                    <label>To:</label>
                    <input
                      type="date"
                      name="endDate"
                      value={endDateValue}
                      onChange={(e) => setEndDateValue(e.target.value)}
                    />
                  </div>
                </div>
                <button
                    className="done"
                    onClick={handleFilter}
                  >
                    Filter
                  </button>
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Filter>

      <InvoiceDiscountCardsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(metric?.totalNumberOfRequest || '0')}</h3>
            <div>
              <p>Total Number of Requests</p>
            </div>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(metric?.totalSumOfBillRequest || '00')}</h3>
            <div>
              <p>Total Amount of Bill Requests</p>
            </div>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.totalNumberOfPaidRequest || '0')}</h3>
            <div>
              <p>Total Number of Paid Requests</p>
            </div>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(metric?.totalSumOfPaidBillRequest || '00')}</h3>
            <div>
              <p>Total Amount of Paid Requests</p>
            </div>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.totalNumberOfPendingRequest || '0')}</h3>
            <div>
              <p>Total Number of Pending Requests</p>
            </div>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(metric?.totalSumOfPendingBillRequest || '00')}</h3>
            <div>
              <p>Total Amount of Pending Requests</p>
            </div>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.totalNumberOfApprovedRequest || '0')}</h3>
            <div>
              <p>Total Number of Approved Requests</p>
            </div>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(metric?.totalSumOfApprovedBillRequest || '00')}</h3>
            <div>
              <p>Total Amount of Approved Requests</p>
            </div>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.totalNumberOfRejectedRequest || '0')}</h3>
            <div>
              <p>Total Number of Rejected Requests</p>
            </div>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.totalNumberOfOverDueRequest || '0')}</h3>
            <div>
              <p>Total Number of Overdue Requests</p>
            </div>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(metric?.totalSumOfOverDueBillRequest || '00')}</h3>
            <div>
              <p>Total Amount of Overdue Requests</p>
            </div>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(metric?.totalSumOfInterestAccrued || '00')}</h3>
            <div>
              <p>Total Amount of Interest Accrued</p>
            </div>
          </Card>
        </div>
        <Divider />

        <TransactionLevel>
          <InvoiceDiscountTable />
        </TransactionLevel>
      </InvoiceDiscountCardsView>
    </>
  );
};

export default InvoiceDiscountCards;

const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  .filter-btn {
    gap: 0.4rem;
    display: flex;
    align-items: center;
  }

  .export-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;
    z-index: 100;
  
    .body {
      margin-top: 20px;
  
      .item {
        width: 100%;
        margin-bottom: 20px;
  
        .date-input {
          gap: 0.4rem;
          display: flex;
          align-items: start;
          margin-bottom: 10px;
          flex-direction: column;
          justify-content: start;
  
          input {
            width: 100%;
            border: none;
            cursor: pointer;
            margin-right: 10px;
            border-radius: 6px;
            padding: 0.5rem 0.65rem;
            color: ${({ theme }) => theme.colors?.black};
            background-color: ${({ theme }) => theme.colors?.inputBackground};
          }
        }
      }
  
      .done {
        margin: 0;
        width: auto;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: 600;
        border-radius: 6px;
        padding: 0.6rem 2rem;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.white};
        background-color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }
`;

const InvoiceDiscountCardsView = styled.div`
  width: 100%;
  height: 130px;
  border-radius: 6px;

  .card-wrapper {
    width: 100%;
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.alternateBackground2};
    }

    h3 {
      font-weight: 600;
    }

    .grey-text {
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const LoaderView = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .loading-view {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const UsersView = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.75rem;
  color: ${({ theme }) => theme.colors.white};

  .dark,
  .light {
    width: 50%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    align-items: center;
    padding: 1rem 2rem;

    .text {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      h3 {
        font-size: 24px;
        font-weight: 600;
      }
    }

    .icon {
      width: 70px;
      height: 70px;
      background-color: rgba(255, 255, 255, 0.07);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }

  .dark {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  .light {
    background-color: ${({ theme }) => theme.colors.primary};

    .wifi {
      position: absolute;
      top: 0;
      right: 6px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.line};
`;

const TransactionLevel = styled.div`
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;

  .title {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
