import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import { numberWithCommas2 } from '@/utils/helpers';
import { Card } from '@/components/MetricsCard';

function ComplianceMetrics({ pending, verified, declined, total }) {
  return (
    <ComplianceMetricsView>
      <div className="card-wrapper">
        <Card>
          <h3>{numberWithCommas2(total || '00.00')}</h3>
          <p>Total submitted</p>
        </Card>
        <Card>
          <h3>{numberWithCommas2(pending || '00.00')}</h3>
          <p>Pending verification</p>
        </Card>
        <Card>
          <h3>{numberWithCommas2(declined || '00.00')}</h3>
          <p>Declined verification</p>
        </Card>
        <Card>
          <h3>{numberWithCommas2(verified || '00.00')}</h3>
          <p>Total verified</p>
        </Card>
      </div>
    </ComplianceMetricsView>
  );
}

export default ComplianceMetrics;

ComplianceMetrics.propTypes = {
  pending: PropTypes.number,
  verified: PropTypes.number,
  declined: PropTypes.number,
  total: PropTypes.number
};

const ComplianceMetricsView = styled.div`
  width: 100%;
  height: 130px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .card-wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.alternateBackground2};
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .grey-text {
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const MetricView = styled.div`
  width: 32%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({ theme }) => theme.colors.line};
`;
