/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Button = styled.button`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '64px'};
  color: ${({ theme, styletype }) =>
    styletype === 'secondary'
      ? theme.colors?.primary
      : styletype === 'alternate'
      ? theme.colors?.black
      : theme.colors?.white};
  background-color: ${({ theme, styletype }) =>
    styletype === 'secondary'
      ? theme.colors?.white
      : styletype === 'alternate'
      ? theme.colors?.inputBackground
      : theme.colors?.secondary};
  border-radius: 6px;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
`;

Button.propTypes = {
  type: PropTypes.string.isRequired,
  styletype: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Button;
