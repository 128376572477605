import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { useState } from 'react';

import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.icon.svg';
import TextArea from '@/components/TextArea';
import { approveOrRejectCompliance } from '@/api/complianceApi';
import FilePicker from '@/components/FilePicker';

function ApprovalModal({ showModal, setShowModal, complianceId }) {
  const queryClient = useQueryClient();
  const [reportFile, setReportFile] = useState(null);
  const [remark, setRemark] = useState('');

  const handleApproveCompliance = useMutation(
    ({ id, body }) => approveOrRejectCompliance(id, body),
    {
      onSuccess: () => {
        toast.success('Compliance approved successfully');
        queryClient.invalidateQueries({ queryKey: ['compliance'] });
        queryClient.invalidateQueries({ queryKey: ['complianceVerification'] });
        setShowModal(false);
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      }
    }
  );

  const onSubmit = () => {
    const formData = {
      action: 'approve',
      remark: remark ? remark : null,
      reportDocUrl: reportFile ? reportFile : null
    };

    if (!formData.reportDocUrl) {
      toast.error('Please upload compliance report');
      return;
    }

    // Remove properties with null values
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== null)
    );

    handleApproveCompliance.mutate({ id: complianceId, body: filteredFormData });
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <ContentView>
                <HeaderView>
                  <h3>Approve compliance</h3>
                  <button className="close" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </HeaderView>

                <Content>
                  <div className="info">
                    <div className="icon">
                      <InfoIcon />
                    </div>
                    <p>Check all required information are correct before proceeding to approve.</p>
                  </div>

                  <div className="input-view">
                    <label htmlFor="comment">
                      Comment <span>(Optional)</span>
                    </label>
                    <TextArea
                      placeholder="Enter comment"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </div>

                  <div className="input-view">
                    <label htmlFor="comment">
                      Compliance report
                    </label>
                    <FilePicker file={reportFile} setFile={setReportFile} />
                  </div>

                  <div className="cta">
                    <button className="cancel" onClick={() => setShowModal(false)}>
                      Cancel
                    </button>
                    <button className="approve" onClick={onSubmit}>
                      {handleApproveCompliance.isLoading ? (
                        <span>
                          <Oval
                            color="#FFF"
                            secondaryColor="#ddd"
                            height={20}
                            width={20}
                            strokeWidth={4}
                            ariaLabel="loading"
                          />
                        </span>
                      ) : (
                        'Approve'
                      )}
                    </button>
                  </div>
                </Content>
              </ContentView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default ApprovalModal;

ApprovalModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  complianceId: PropTypes.string.isRequired
};

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 480px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 6px;
    max-height: 90%;
    overflow-y: scroll;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;
`;

const HeaderView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
  }

  .close {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #f3f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 30px;

  .info {
    width: 100%;
    background-color: ${(props) => props.theme.colors?.warningBackground};
    border-radius: 6px;
    padding: 14px;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors?.secondary};
    }
  }

  .input-view {
    width: 100%;
    margin-top: 30px;

    label {
      font-size: 0.875rem;
    }

    .error-label {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.error_200};
      margin-top: 5px;
    }
  }

  .cta {
    width: 100%;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .approve {
      width: 120px;
      height: 40px;
      border-radius: 6px;
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};
      font-size: 0.9rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }
  }
`;
