import PageHeader from '@/components/PageHeader';
import withAuthentication from '@/hooks/withAuthentication';
import { styled } from 'styled-components';
import TransactionsTable from './components/TransactionsTable';
import TransactionFees from './components/fees/TransactionFees';
import TransactionsMetrics from './components/TransactionsMetrics';
// import AllTransactions from './components/AllTransactions';
import { usePermissions } from '@/hooks/usePermissions';
import { EmptyState } from '@/components/EmptyState';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { TransactionTable } from './components/transactionTables/TransactionTable';

const tabs = [
  {
    name: "Transactions Table",
    path: "table&view=all",
    value: "table",
  },
  {
    name: "Transaction Fees",
    path: "fees",
    value: "fees",
  }
];

function Transactions() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      setSearchParams('tab=table&view=all');
      // Optionally, you can also navigate to the new URL
      navigate('?tab=table&view=all');
    }
  }, [searchParams, navigate, setSearchParams]);

  const canReadTransactions = hasPermission('transaction', 'read');
  return (
    <>
      <PageHeader title="Transactions" subtitle="View and manage transactions" />
      <TransactionsView>
        {canReadTransactions ? (
          <div>
            <TransactionsMetrics />
            {/* <AllTransactions /> */}
            <TabView>
              {tabs.map((item) => (
                <button
                  key={item.name}
                  className={`tab ${tab === item.value ? 'active' : ''}`}
                  onClick={() => setSearchParams(`tab=${item.path}`)}>
                  {item.name}
                </button>
              ))}
            </TabView>
            <TabContent>{tab === 'table' && <TransactionTable />}</TabContent>
            <TabContent>{tab === 'fees' && <TransactionFees />}</TabContent>
          </div>
        ) : (
          <EmptyState />
        )}
        {/* <div>
          <TransactionsMetrics />
          <AllTransactions />
        </div> */}
      </TransactionsView>
    </>
  );
}

export default withAuthentication(Transactions);

const TransactionsView = styled.div`
  /* width: calc(100% - 18%);
  padding: 0px 30px 0px 30px; */

  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;

const TabView = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  /* position: fixed; */
  z-index: 100;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};

  button {
    padding: 16px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
    color: ${(props) => props.theme?.colors?.secondary};
    }
  }
  
  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;

const TabContent = styled.div`
  width: 100%;
`;