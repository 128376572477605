import PageHeader from "@/components/PageHeader";
import withAuthentication from "@/hooks/withAuthentication";
import styled from "styled-components";
import InvoiceDiscountCards from "./components/InvoiceDiscountCards";
import { usePermissions } from "@/hooks/usePermissions";
import { EmptyState } from "@/components/EmptyState";

const InvoiceDiscount = () => {
  const { hasPermission } = usePermissions();
  const canReadInvoiceDiscount = hasPermission('invoice-discounting', 'read');

  return (
    <>
      <PageHeader title="Invoice Discount" subtitle="View and manage subscriptions" />
      <InvoiceDiscountView>
        {canReadInvoiceDiscount ? (
          <InvoiceDiscountCards />
        ) : (
          <EmptyState />
        )}
      </InvoiceDiscountView>
    </>
  );
}

export default withAuthentication(InvoiceDiscount);

const InvoiceDiscountView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;