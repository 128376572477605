import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.icon.svg';
import Input from '@/components/Input';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchTransactionFee, updateTransactionFee } from '@/api/transactionApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import PageLoader from '@/loaders/PageLoader';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/nav-down.svg';
import CurrencyyInput from '@/components/CurrencyyInput';

const UpdateTransactionFee = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [updateFee, setUpdateFee] = useState({
    category: '',
    type: '',
    min: 0,
    max: 0,
    flat: 0,
    vat: 0.0,
  });

  const fee = useQuery({
    queryKey: ['fee', id],
    queryFn: fetchTransactionFee,
    onSuccess: (response) => {
      const data = response.data;
      
      setUpdateFee({
        ...updateFee,
        category: data?.category || '',
        type: data?.type || '',
        min: data?.min || 0,
        max: data?.max || 0,
        flat: data?.flat || 0,
        vat: data?.vat || 0.0,
      })
    },
    onError: (error) => {
    }
  });

  const handleChange = (e) => {
    const { name, type, value } = e.target;
    const newValue = type === 'number' ? parseInt(value) : value;
    const updatedData = { ...updateFee, [name]: newValue };
    if (name === 'type' && value === 'percentage') {
      updatedData.flat = 0;
    }
    setUpdateFee(updatedData);
  }

  const handleCostChange = (fieldName) => (newValue) => {
    setUpdateFee({ ...updateFee, [fieldName]: newValue });
  }

  const handleVAT = (e) => {
    const vatValue = e.target.value;

    if (/^\d*\.?\d*$/.test(vatValue) || vatValue === '') {
      setUpdateFee((prevData) => ({ ...prevData, vat: parseFloat(vatValue) }));
    }
  }

  const handleUpdateFee = useMutation({
    mutationFn: ({ id, data}) => updateTransactionFee(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries('fees');  
      navigate('/transactions?tab=transactions&view=fees');
      toast.success('Transaction fee updated successfully');
    }
  });

  const onSubmit = () => {
    handleUpdateFee.mutate({ id: id, data: updateFee });
  }

  return (
    <AnimatePresence>
      {fee.isFetching ? (
        <PageLoader />
      ) : (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <ContentView>
                <HeaderView>
                  <h3>Update a Transaction Fee</h3>
                  <button 
                    className="close" 
                    onClick={() => navigate('/transactions?tab=transactions&view=fees')}
                  >
                    <CloseIcon />
                  </button>
                </HeaderView>
                <Content>
                  <div className="info">
                    <div className="icon">
                      <InfoIcon />
                    </div>
                    <p>
                      Please edit the necessary fields to enable you update a transaction fee.
                    </p>
                  </div>
                  <DataView>
                    <InputView>
                      <LabelView>
                        <label>Category</label>
                      </LabelView>
                      <Input
                        type="text"
                        name="category"
                        placeholder="Enter category"
                        value={updateFee.category}
                        onChange={handleChange}
                        disabled
                      />
                    </InputView>
                    <InputView>
                      <LabelView>
                        <label>Type</label>
                      </LabelView>
                      <select 
                        name="type"
                        value={updateFee.type} onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="flat">Flat</option>
                        <option value="percentage">Percentage</option>
                      </select>
                      <button className="icon" type="button">
                        <ArrowDownIcon />
                      </button>
                    </InputView>
                  </DataView>
                  <DataView>
                    <InputView>
                      <LabelView>
                        <label>Min. Amount</label>
                      </LabelView>
                      <CurrencyyInput 
                        name="min"
                        placeholder="Enter min. amount"
                        value={updateFee.min}
                        onValueChange={handleCostChange('min')}
                      />
                    </InputView>
                    <InputView>
                      <LabelView>
                        <label>Max. Amount</label>
                      </LabelView>
                      <CurrencyyInput 
                        name="max"
                        placeholder="Enter max. amount"
                        value={updateFee.max}
                        onValueChange={handleCostChange('max')} 
                      />
                    </InputView>
                  </DataView>
                  <DataView>
                    <InputView>
                      <LabelView>
                        <label>{updateFee.type === 'percentage' ? 'Percentage' : 'Flat'}</label>
                      </LabelView>
                      <Input 
                        className='input'
                        type='number'
                        name={updateFee.type === 'percentage' ? 'percentage' : 'flat'}
                        placeholder={updateFee.type === 'percentage' ? 'Enter percentage' : 'Enter flat'}
                        value={updateFee.type === 'percentage' ? updateFee.percentage : updateFee.flat}
                        onChange={handleChange}
                      />
                    </InputView>
                    <InputView>
                      <LabelView>
                        <label>V.A.T</label>
                      </LabelView>
                      <Input
                        type="number"
                        className='input'
                        name="vat"
                        placeholder="Enter V.A.T"
                        value={updateFee.vat}
                        onChange={handleVAT}
                      />
                    </InputView>
                  </DataView>

                  <div className="cta">
                    <button 
                      className="cancel" 
                      onClick={() => navigate('/transactions?tab=transactions&view=fees')}
                    >
                      Cancel
                    </button>
                    <button className="approve" onClick={onSubmit}>
                      {handleUpdateFee.isLoading ? (
                        <span>
                          <Oval
                            color="#FFF"
                            secondaryColor="#ddd"
                            height={20}
                            width={20}
                            strokeWidth={4}
                            ariaLabel="loading"
                          />
                        </span>
                      ) : (
                        'Update Fee'
                      )}
                    </button>
                  </div>
                </Content>
              </ContentView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
};

export default UpdateTransactionFee;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 50vw;
    padding: 30px 10px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 6px;
    max-height: 90%;
    overflow-y: scroll;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;
`;

const HeaderView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
  }

  .close {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #f3f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const DataView = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
  background-color: ${({ theme }) => theme.colors?.white};

  .disabled {
    background-color: #f2f2f2;
    color: #999999;
  }

  .check {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 0;
    gap: 1rem;

    .radio-input {
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  position: relative;

  .input::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .currency-input {
    width: 100%;
    height: 64px;
    outline: none;
    margin-top: 5px;
    font-size: 1rem;
    font-weight: 300;
    text-indent: 15px;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
    border: 1px solid ${({ theme }) => theme.colors?.inputBackground};

    &:focus {
      background-color: ${({ theme }) => theme.colors?.white};
      border: 1px solid ${({ theme }) => theme.colors?.activeTitle};
    }

    &:disabled {
      background-color: #f2f2f2;
      color: #999999;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 1rem;
      font-weight: 300;
    }
  }

  select {
    padding: 0 1rem;
    margin-top: 5px;
    width: 100%;
    height: 75%;
    border-radius: 5px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 20px;
    font-weight: 600;
    background-color: ${({ theme }) => theme.colors?.inputBackground};

    &:disabled {
      color: #999999;
      background-color: #f2f2f2;
    }
  }

  .icon {
    background-color: transparent;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    position: absolute;
    top: 2.85rem;
    right: 12px;
    cursor: pointer;

    svg {
      transition: all 0.3s ease-in-out;
      transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
      transform-origin: 50% 50%;
      width: 20px;
      height: 15px;
    }
  } 

  .input {
    position: relative;

    button {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 5px;
    }
  }

  .error-label {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.error_200};
    margin-top: 5px;
  }
`;

const LabelView = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0px;

  label {
    font-size: 1rem;
  }
`;

const ButtonView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 40px 0;

  .btn-disabled {
    cursor: not-allowed;
    color: #F2F2F2;
    background-color: ${({ theme }) => theme.colors?.info};
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 30px;

  .info {
    width: 100%;
    background-color: ${(props) => props.theme.colors?.warningBackground};
    border-radius: 6px;
    padding: 14px;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors?.secondary};
    }
  }

  .input-view {
    width: 100%;
    margin-top: 30px;

    .calendar {
      border-radius: 1rem;
      margin-bottom: 2rem;
    }

    label {
      font-size: 0.875rem;
    }

    .error-label {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.error_200};
      margin-top: 5px;
    }

    .select-view {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 8px;

      .select-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;

        .checkbox {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          input {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            cursor: pointer;
            accent-color: ${(props) => props.theme.colors?.secondary};
            color: ${(props) => props.theme.colors?.primary};
          }

          label {
            font-size: 1rem;
            font-weight: 500;
            color: ${(props) => props.theme.colors?.secondary};
            cursor: pointer;
          }
        }
      }
    }
  }

  .cta {
    width: 100%;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .approve {
      width: 120px;
      height: 40px;
      border-radius: 6px;
      background-color: ${(props) => props.theme.colors?.secondary};
      color: ${(props) => props.theme.colors?.white};
      font-size: 0.9rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }
  }
`;