import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { numberWithCommas } from '@/utils/helpers';
import { updateDiscount } from '@/api/lendersApi';
import Input from '@/components/Input';

function UpdateDiscountModal({ showModal, setShowModal, selectedItem, setSelectedItem }) {
  const [amount, setAmount] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const queryClient = useQueryClient();

  const recordAmount = useMutation({
    mutationFn: (data) => updateDiscount(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['lenders']);
      // setAmount(0);
      setPercentage(0);
      setShowModal(false);
      setSelectedItem({});
      toast.success('Percentage successfully updated');
    }
  });

  const handleChange = (value) => {
    setAmount(value);
  };

  const handlePercentage = (event) => {
    const value = event.target.value
    setPercentage(value);
  };

  const handleSubmit = () => {
    const data = {
      userId: selectedItem?.id,
      percentage: percentage
    };
    recordAmount.mutate(data);
    console.log(data);
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <div className="header">
                <button
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                    setSelectedItem({});
                    setAmount(0);
                  }}>
                  <CloseIcon />
                </button>
              </div>
              <ContentView>
                <div className="content">
                  <div className="align">
                    <h3>Update Percentage</h3>
                    <p>Enter percentage</p>
                  </div>

                  <Input
                    className="input"
                    type="text"
                    name='percentage'
                    placeholder='Enter percentage'
                    value={percentage}
                    onChange={handlePercentage}
                  />

                  {amount > selectedItem?.repaymentAmount && (
                    <span className="error">
                      The amount you entered is greater than
                      <span className="strong">
                        ₦{numberWithCommas(selectedItem?.repaymentAmount || '00.00')}
                      </span>
                    </span>
                  )}

                  <div className="btn">
                    <button
                      type="button"
                      className="continue"
                      onClick={() => {
                        setShowModal(false);
                        setSelectedItem({});
                        setAmount(0);
                        setPercentage(0);
                      }}>
                      No, Cancel
                    </button>
                    <button type="button" className="activate" onClick={() => handleSubmit()}>
                      {recordAmount.isLoading ? (
                        <Oval height={20} width={20} color="#c2bdbd" secondaryColor="#ddd" />
                      ) : (
                        'Update Percentage'
                      )}
                    </button>
                  </div>
                </div>
              </ContentView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default UpdateDiscountModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 35vw;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .input::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

    .error {
      margin: 0.45rem 0;
      margin-left: -3rem;
      text-align: start;
      color: ${(props) => props.theme.colors?.error};

      .strong {
        font-weight: 500;
      }
    }

    .icon {
      path {
        stroke: ${(props) => props.theme.colors?.error_200};
      }
    }

    .green-icon {
      path {
        stroke: ${(props) => props.theme.colors?.warning};
      }
    }

    .align {
      width: 100%;

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 10px;
        color: ${(props) => props.theme.colors?.secondary};
        margin-top: 10px;
        text-align: start;
      }

      p {
        padding-top: 1rem;
        margin-bottom: 0;
        font-size: 0.9rem;
        font-weight: 400;
        color: ${(props) => props.theme.colors?.darkGray};
        margin-bottom: 20px;
        line-height: 19px;
      }
    }

    .warn {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      margin: 10px 0;
      background-color: #fdebeb;
      font-weight: 500;
      border-radius: 10px;

      h2 {
        width: 100%;
        font-weight: 500;
        line-height: 19px;
        color: ${(props) => props.theme.colors?.error_200};
      }
    }

    .currency-input {
      width: 100%;
      height: 64px;
      outline: none;
      margin-top: -7px;
      font-size: 1rem;
      font-weight: 300;
      text-indent: 15px;
      border-radius: 10px;
      transition: all 0.2s ease-in-out;
      background-color: ${({ theme }) => theme.colors?.inputBackground};
      border: 1px solid ${({ theme }) => theme.colors?.inputBackground};

      &:focus {
        background-color: ${({ theme }) => theme.colors?.white};
        border: 1px solid ${({ theme }) => theme.colors?.activeTitle};
      }

      &:disabled {
        background-color: #f2f2f2;
        color: #999999;
      }

      &::placeholder {
        color: ${({ theme }) => theme.colors?.placeholder};
        font-size: 1rem;
        font-weight: 300;
      }
    }

    .btn {
      width: 100%;
      gap: 1rem;
      display: flex;
      align-items: center;

      .activate {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        background-color: ${(props) => props.theme.colors?.primary};
        height: 64px;
        border-radius: 16px;
        color: ${(props) => props.theme.colors?.white};
        font-weight: 500;
      }

      .continue {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        background-color: ${(props) => props.theme.colors?.white};
        border: 1px solid ${(props) => props.theme.colors?.info};
        height: 64px;
        border-radius: 16px;
        color: ${(props) => props.theme.colors?.info};
        font-weight: 500;
      }
    }
  }
`;
