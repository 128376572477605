import api from "./api";

export const fetchVirtualAccounts = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `/admin/virtual-accounts?page=${page}&limit=${limit}`;
  
  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchVirtualAccount = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/virtual-accounts/${id}`);
  return response.data;
}

export const fetchVirtualAccountMetrics = async () => {
  const response = await api.get(`admin/virtual-accounts/metrics`)
  return response.data;
}

export const exportVirtualAccount = async (data) => {
  const response = await api.post(`/admin/export/virtualAccount`, data);
  return response.data;
}