import { styled } from 'styled-components';
import { format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow-tr.icon.svg';
import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import { ReactComponent as ArrowUpIcon } from '@/assets/icons/arrow-up.icon.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.icon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import { ReactComponent as NextIcon } from '@/assets/icons/nav-right.icon.svg';
import { ReactComponent as PrevIcon } from '@/assets/icons/nav-left.icon.svg';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSortableData } from '@/hooks/useSortableData';
import { exportCompliance, fetchCompliances } from '@/api/complianceApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import useOnClickOutside from '@/hooks/useClickOutside';
import TableLoader from '@/loaders/TableLoader';
import ComplianceMetrics from './ComplianceMetrics';
import useDebounce from '@/hooks/useDebounce';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';

function ComplianceTable() {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showMenu, setShowMenu] = useState(false);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showExportMenu, setShowExportMenu] = useState(false);
  const [fetch, setFetch] = useState('');
  const [fetchValue, setFetchValue] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderByValue, setOrderByValue] = useState('');
  const [search, setSearch] = useState('');
  const PAGE_LIMIT = 10;
  // const [page, setPage] = useState(1);
  const debouncedsearch = useDebounce(search, 500);
  const [data, setData] = useState({
    startDate: null,
    endDate: null
  });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = params.get('page'); 
  const [page, setPage] = useState(currentPage ? parseInt(currentPage) : 1);

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const filterRef = useRef();
  useOnClickOutside(filterRef, () => setShowFilterMenu(false));

  const exportRef = useRef();
  useOnClickOutside(exportRef, () => setShowExportMenu(false));

  const filterOptions = [
    {value: 'currentWeek', label: 'Current Week'},
    {value: 'lastWeek', label: 'Last Week'},
    {value: 'currentMonth', label: 'Current Month'},
    {value: 'lastMonth', label: 'Last Month'},
    {value: 'currentYear', label: 'Current Year'},
    {value: 'lastYear', label: 'Last Year'},
  ];

  const compliances = useQuery({
    queryKey: ['compliance', { page, limit: PAGE_LIMIT, search: debouncedsearch, orderBy, fetch }],
    queryFn: fetchCompliances,
    keepPreviousData: true
  });

  //Export
  const exportCompliances = useMutation({
    mutationFn: (data) => exportCompliance(data),
    onSuccess: (data) => {
      setData({
        startDate: null,
        endDate: null
      });
      setShowExportMenu(false);
      toast.success(data.message);
    }
  });

  const handleData = (e) => {
    const { name, value } = e.target;
    const date = new Date(value);
    // Format the date to "yyyy-MM-dd" format
    const formattedDate = date.toISOString().split('T')[0];

    setData(prevData => ({
      ...prevData,
      [name]: formattedDate
    }));
  }

  const handleExport = () => {
    exportCompliances.mutate(data);
  }

  // Sort table
  const { items, requestSort, sortConfig } = useSortableData(
    compliances?.data?.data?.compliance || []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  // Fetch next page
  const fetchNextPage = () => {
    const totalPage = Math.ceil(compliances?.data?.data?.meta?.totalItems / PAGE_LIMIT);
    if (page < totalPage) {
      setPage(page + 1);
      navigate(`/compliance?page=${page + 1}`);
    }
  };

  // Fetch previous page
  const fetchPrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
      navigate(`/compliance?page=${page - 1}`);
    }
  };

  if (compliances.isFetching && !search) {
    return <TableLoader />;
  }

  return (
    <ComplianceTableView>
      <ComplianceMetrics
        pending={compliances?.data?.data?.pending}
        verified={compliances?.data?.data?.verified}
        declined={compliances?.data?.data?.declined}
        total={compliances?.data?.data?.meta?.totalItems}
      />
      <TableHeader>
        <SearchView>
          <SearchIcon />
          <input
            type="search"
            placeholder="Search compliance"
            onChange={(e) => setSearch(e.target.value.trim())}
          />
          {compliances.isFetching && search && <Oval width={20} height={20} strokeWidth={4} />}
        </SearchView>
        <FilterView>
          <button 
            className="export"
            onClick={() => setShowExportMenu(true)}
          >
            <ArrowIcon /> Export
          </button>
          {/* ===== Export Menu ====== */}
          <AnimatePresence>
            {showExportMenu ? (
              <motion.div
                className="export-menu"
                ref={exportRef}
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}>
                <div className="heading">
                  <span>Export</span>
                 
                </div>
                <div className="body">
                  <div className="item">
                    <div className="date-input">
                      <label>From:</label>
                      <input 
                        type="date" 
                        name="startDate" 
                        value={data.startDate}
                        onChange={handleData} 
                      />
                    </div>
                    <div className="date-input">
                      <label>To:</label>
                      <input 
                        type="date" 
                        name="endDate" 
                        value={data.endDate}
                        onChange={handleData} 
                      />
                    </div>
                  </div>
                  <button
                    className="done"
                    onClick={handleExport}
                  >
                    {exportCompliances?.isLoading ? (
                      <Oval height={20} width={20} color="#c2bdbd" secondaryColor="#ddd" />
                    ) : (
                      'Export'
                    )}
                  </button>
                </div>
              </motion.div>
            ) : null}
          </AnimatePresence>

          <button className="filter" onClick={() => setShowFilterMenu(true)}>
            <FilterIcon /> Filter
          </button>
          {/* ===== Filter Menu ====== */}
          <AnimatePresence>
            {showFilterMenu ? (
              <motion.div
                className="filter-menu"
                ref={filterRef}
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}>
                <div className="heading">
                  <span>Filters</span>
                  <button
                    className="done"
                    onClick={() => {
                      setFetch(fetchValue);
                      setOrderBy(orderByValue);
                      setShowFilterMenu(false);
                    }}>
                    Done
                  </button>
                </div>
                <div className="body">
                  <div className="item">
                    <h3>Duration</h3>
                    {filterOptions.map((option) => {
                      return (
                        <div className="input" key={option.value}>
                          <input
                            type="radio"
                            name="duration"
                            value={option.value}
                            onChange={(e) => {
                              setFetchValue(e.target.value);
                            }}
                          />
                          <label>{option.label}</label>
                        </div>
                      )
                    })}
                  </div>
                  <div className="item">
                    <h3>Order</h3>
                    <select
                      name="plan"
                      id="plan"
                      onChange={(e) => {
                        setOrderByValue(e.target.value);
                      }}>
                      <option value="">Select order</option>
                      <option value="ASC">Ascending</option>
                      <option value="DESC">Descending</option>
                    </select>
                  </div>
                </div>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </FilterView>
      </TableHeader>
      <TableHead>
        <div className={`type ${getClassNamesFor('user')}`} onClick={() => requestSort('user')}>
          <span>Fullname</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className={`type ${getClassNamesFor('user')}`} onClick={() => requestSort('user')}>
          <span>Business name</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div
          className={`reg ${getClassNamesFor('registrationNumber')}`}
          onClick={() => requestSort('registrationNumber')}>
          <span>Reg No.</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className={`tin ${getClassNamesFor('tin')}`} onClick={() => requestSort('tin')}>
          <span>TIN</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div
          className={`status ${getClassNamesFor('isVerified')}`}
          onClick={() => requestSort('isVerified')}>
          <span>Status</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div
          className={`date ${getClassNamesFor('createdAt')}`}
          onClick={() => requestSort('createdAt')}>
          <span>Date</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className="actions"></div>
      </TableHead>

      <TableBody>
        {items.map((item, i) => {
          return (
            <div key={item?.id} className="item">
              <div className="type">
                <span>
                  {item?.user?.firstName || '-'} {item?.user?.lastName || '-'}
                </span>
              </div>
              <div className="type">
                {item?.user?.companies?.length > 0 ? (
                  <span>{item?.user?.companies[0]?.name || '-'}</span>
                ) : (
                  <span>-</span>
                )}
              </div>
              <div className="reg">
                <span>{item.registrationNumber || '-'}</span>
              </div>
              <div className="tin">
                <span>{item.tin || '-'}</span>
              </div>
              <Status className="status" status={item?.verifications[0]?.status}>
                {item?.isVerified || item?.verifications?.length !== 0 ? (
                  <div>
                    <span className="dot"></span> {item?.verifications[0]?.status}
                  </div>
                ) : 
                (
                  <div className='pending'>
                    <span className="dot"></span> Pending
                  </div>
                )}
              </Status>
              <div className="date">
                <span>{format(new Date(item.createdAt), 'do MMM, yyyy')}</span>
              </div>
              <div className="actions">
                <button
                  className="menu-icon"
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                  }}>
                  <MoreIcon />
                </button>

                {/* ===== Dropdown Menu ====== */}
                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      className="menu-actions"
                      ref={ref}
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        onClick={() => {
                          navigate(`/compliance/${item?.id}?page=${page}`);
                        }}>
                        <span>View compliance</span>
                      </button>
                      {!item?.isVerified && (
                        <button
                          onClick={() => {
                            navigate(`/compliance/${item?.id}`);
                          }}>
                          <span>Verify compliance</span>
                        </button>
                      )}
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            </div>
          );
        })}
      </TableBody>

      {/* ===== Pagination ====== */}
      <PaginationView>
        <div className="total">
          {/* <span>{compliances?.data?.data?.meta?.size} results</span> */}
        </div>
        <div className="paginate">
          <button className="prev" onClick={fetchPrevPage}>
            <PrevIcon />
          </button>
          <span>
            Page {page} of {Math.ceil(compliances?.data?.data?.meta?.totalItems / PAGE_LIMIT)}
          </span>
          <button className="next" onClick={fetchNextPage}>
            <NextIcon />
          </button>
        </div>
      </PaginationView>
    </ComplianceTableView>
  );
}

export default ComplianceTable;

const ComplianceTableView = styled.div`
  width: 100%;
  height: 100%;
`;

const getStatusColor = (status) => {
  switch (status) {
    case 'approve':
      return '#16A34A';
    case 'pending':
      return '#F4B740';
    case 'declined':
      return '#B91C1C';

    default:
  }
}

const Status = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: ${({ status }) => getStatusColor(status)};

  div {
    display: flex;
    align-items: center;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
      background-color: ${({ status }) => getStatusColor(status)};
    }
  }
`

const TableHeader = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.line};
  border-left: none;
  border-right: none;
  margin-top: 40px;
`;

const SearchView = styled.div`
  width: 40%;
  height: 40px;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    margin-left: 10px;
    font-weight: 400;

    &::placeholder {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.placeholder};
    }
  }
`;

const FilterView = styled.div`
  width: 30%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  .filter-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      span {
        font-size: 1.1rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.black};
      }

      .done {
        background-color: transparent;
        color: ${({ theme }) => theme.colors?.secondary};
        border: none;
        outline: none;
        font-size: 0.875rem;
        font-weight: 600;
        cursor: pointer;
        margin: 0;
        padding: 0;
        width: auto;
      }
    }

    .body {
      .item {
        width: 100%;
        margin-bottom: 20px;

        h3 {
          font-size: 1rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors?.black};
          margin-bottom: 10px;
        }

        .input {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          input {
            margin-right: 10px;
            accent-color: ${({ theme }) => theme.colors?.secondary};
            cursor: pointer;
          }
        }

        select {
          width: 100%;
          height: 40px;
          outline: none;
          border: 1px solid ${({ theme }) => theme.colors.line};
          border-radius: 6px;
          padding: 0 10px;
          font-size: 0.875rem;
          font-weight: 400;
          color: ${({ theme }) => theme.colors?.placeholder};
          cursor: pointer;
        }
      }
    }
  }

  .export-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;

    .body {
      margin-top: 20px;

      .item {
        width: 100%;
        margin-bottom: 20px;

        .date-input {
          gap: 0.4rem;
          display: flex;
          align-items: start;
          margin-bottom: 10px;
          flex-direction: column;
          justify-content: start;

          input {
            width: 100%;
            border: none;
            cursor: pointer;
            margin-right: 10px;
            border-radius: 6px;
            padding: 0.5rem 0.65rem;
            color: ${({ theme }) => theme.colors?.black};
            background-color: ${({ theme }) => theme.colors?.inputBackground};
          }
        }
      }

      .done {
        margin: 0;
        width: auto;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: 600;
        border-radius: 6px;
        padding: 0.6rem 2rem;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.white};
        background-color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }

  button {
    width: 120px;
    height: 100%;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      width: 10px;
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors.black};

    .up path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors.black};

    .down path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .type {
    flex: 1;
  }

  .year {
    flex: 0.8;
  }

  .reg {
    flex: 0.6;
  }

  .tin {
    flex: 0.6;
  }

  .status {
    flex: 0.5;
  }

  .date {
    flex: 0.5;
  }

  .actions {
    flex: 0.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .type {
      flex: 1;
    }

    .year {
      flex: 0.8;
    }

    .reg {
      flex: 0.6;
    }

    .tin {
      flex: 0.6;
    }

    .status {
      flex: 0.5;

      .verified {
        color: ${({ theme }) => theme.colors?.success};
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.75rem;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.success};
          margin-right: 4px;
        }
      }

      .pending {
        color: ${({ theme }) => theme.colors?.warning};
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.75rem;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.warning};
          margin-right: 4px;
        }
      }
    }

    .date {
      flex: 0.5;
    }

    .actions {
      flex: 0.5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
  }
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    button {
      width: 40px;
      height: 40px;
      border: none;
      outline: none;
      background-color: ${({ theme }) => theme.colors?.layer3};
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: ${({ theme }) => theme.colors?.layer2};
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;
