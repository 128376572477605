import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authenticated: false,
  user: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAuth: (state, { payload }) => {
      state.authenticated = payload;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    resetAppStateOnLogout: () => {},
  },
});

export const {
  resetAppStateOnLogout,
  setAuth,
  setUser
} = appSlice.actions;
export default appSlice.reducer;
export const appSelector = (state) => state.app;
