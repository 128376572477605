import PageHeader from '@/components/PageHeader';
import withAuthentication from '@/hooks/withAuthentication'
import React from 'react'
import styled from 'styled-components';
import Tabs from './components/Tabs';

const Leaderboard = () => {
  return (
    <>
      <PageHeader title='Leaderboard' subtitle='View and manage leaderboad' />
      <LeaderboardView>
        <Tabs/>
      </LeaderboardView>
    </>
  )
}

export default withAuthentication(Leaderboard);

const LeaderboardView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 80px;
  overflow-x: hidden;
  margin-left: 18%;
`;