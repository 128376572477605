import api from "./api"

export const fetchWallets = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `/admin/wallets?page=${page}&limit=${limit}`;
  
  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchWallet = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/wallets/${id}`);
  return response.data;
}

export const fetchTransactionHistory = async ({ queryKey }) => {
  const [, { id, page, limit, search, walletType }] = queryKey;
  let url = `/admin/wallets/${id}/ledger?page=${page}&limit=${limit}`

  if (search) {
    url += `&search=${search}`;
  }

  if (walletType) {
    url += `&walletType=${walletType}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchWalletMetrics = async({ queryKey }) => {
  const [, { fetch }] = queryKey;
  let url = `/admin/wallets/metrics?fetch=${fetch}`;
  const response = await api.get(url);
  return response.data;
}

export const fetchWalletLedgers = async ({ queryKey }) => {
  const [, { page, limit, search, status }] = queryKey;
  let url = `/admin/wallets/ledgers?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }
  if (status) {
    url += `&status=${status}`;
  }
  const response = await api.get(url);
  return response.data;
}

export const fetchWalletLedger = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`https://api-staging.billboxx.com/admin/wallets/ledger/${id}`);
  return response.data;
}

export const fetchLedgerMetrics = async ({ queryKey }) => {
  const [, { event }] = queryKey;
  const response = await api.get(`/admin/wallets/ledgers/metrics?event=${event}`);
  return response.data;
}

export const exportWallet = async (data) => {
  const response = await api.post(`/admin/export/wallet`, data);
  return response.data;
}

export const walletReconciliation = async (data) => {
  const response = await api.post(`/admin/reconciliation/wallet`, data)
  return response.data;
}

export const fetchWalletState = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `/admin/wallets/state?page=${page}&limit=${limit}`

  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
}