import { fetchVirtualAccountMetrics } from "@/api/virtualAccountsApi";
import { Card } from "@/components/MetricsCard";
import { numberWithCommas2 } from "@/utils/helpers";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

const VirtualAccountMetrics = () => {
  const metrics = useQuery({
    queryKey: ['metrics'],
    queryFn: fetchVirtualAccountMetrics
  })

  const metric = metrics?.data?.data;

  return  (
    <>
      <VirtualAccountMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(metric?.totalAccounts || '0')}</h3>
            <p>Total Accounts</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.totalActiveAccounts || '0')}</h3>
            <p>Total Active Accounts</p>
          </Card>
          <Divider  />
          <Card>
            <h3>{numberWithCommas2(metric?.totalBlockedAccounts || '0')}</h3>
            <p>Total Blocked Accounts</p>
          </Card>
        </div>
      </VirtualAccountMetricsView>
    </>
  )
}

export default VirtualAccountMetrics;

const VirtualAccountMetricsView = styled.div`
  width: 100%;
  height: 130px;
  margin-bottom: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .card-wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.alternateBackground2};
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .grey-text {
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const MetricView = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({ theme }) => theme.colors.line};
`;