import api from './api';

export const fetchAllTransfers = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `/admin/transfers?page=${page}&limit=${limit}`;
  
  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
};

export const fetchInflowTransfers = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `/admin/transfers/state/inflows?page=${page}&limit=${limit}`;
  
  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
}
export const fetchOutlowTransfers = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `/admin/transfers/state/outflows?page=${page}&limit=${limit}`;
  
  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchTransfer = async ({ queryKey }) => {
  const [, { id, type }] = queryKey;
  const response = await api.get(`/admin/transfers/${id}?type=${type}`);
  return response.data;
};

export const fetchTransferMetrics = async ({ queryKey }) => {
  const [, { fetch }] = queryKey;
  let url = `/admin/transfers/metrics?fetch=${fetch}`;
  const response = await api.get(url);
  return response.data;
};

export const exportTransfer = async (data) => {
  const response = await api.post(`/admin/export/transfer`, data);
  return response.data;
}

export const transferReconciliation = async (data) => {
  const response = await api.patch(`/admin/reconciliation/transfer`, data);
  return response.data;
}