import { fetchSubscriptionMetrics } from "@/api/subscriptionApi";
import { Card } from "@/components/MetricsCard";
import { numberWithCommas2 } from "@/utils/helpers";
import { useQuery } from "@tanstack/react-query";
import { Oval } from "react-loader-spinner";
import styled from "styled-components";

function SubscriptionMetrics() {
  const metrics = useQuery({
    queryKey: ['metrics'],
    queryFn: fetchSubscriptionMetrics,
    keepPreviousData: true
  });

  const metric = metrics?.data?.data;

  if (metrics?.isFetching) {
    return (
      <LoaderView>
        <div className="loading-view">
          <Oval color="#92C22C" width={50} height={50} strokeWidth={5} />
        </div>
      </LoaderView>
    );
  }

  return (
    <>
      <SubscriptionMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(metric?.totalSubscriptionPlan || '0')}</h3>
            <p>Total Subscription Plans</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.totalSubscribedUsers || '0')}</h3>
            <p>Total Subscribed Users</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.totalMonthlySubscribedUsers || '0')}</h3>
            <p>Total Monthly Subscribers</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.totalQuarterlySubscribedUsers || '0')}</h3>
            <p>Total Quarterly Subscribers</p>
          </Card>
        </div>
      </SubscriptionMetricsView>

      <SubscriptionMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(metric?.totalAnnuallySubscribedUsers || '0')}</h3>
            <p>Total Annually Subscribers</p>
          </Card>
          <Card>
            <h3>{metric?.mostSubscribedPlan?.name}</h3>
            <p>Most Subscribed Plan</p>
          </Card>
        </div>
      </SubscriptionMetricsView>
    </>
  );
}

export default SubscriptionMetrics;

const SubscriptionMetricsView = styled.div`
  width: 100%;
  height: 130px;
  border-radius: 6px;
  margin-bottom: 20px;
  /* border: 1px solid ${({ theme }) => theme.colors.line}; */
  display: flex;
  align-items: center;
  justify-content: space-between;

  .card-wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.alternateBackground2};
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .grey-text {
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const LoaderView = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .loading-view {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const MetricView = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({ theme }) => theme.colors.line};
`;
