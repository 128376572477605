import { createSubscription } from '@/api/subscriptionApi';
import Button from '@/components/Button';
import CurrencyyInput from '@/components/CurrencyyInput';
import Input from '@/components/Input';
import PageHeader from '@/components/PageHeader';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/nav-down.svg';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';

const CreateSubscription = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isFormValid, setIsFormValid] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState({
    name: '',
    monthlyCost: 0,
    quarterlyCost: 0,
    annualCost: 0,
    status: '',
    data: {
      inbox: false,
      users: 0,
      outbox: false,
      debit_card: false,
      bill_volume: 0,
      bulk_upload: false,
      credit_line: false,
      file_storage: 0,
      instant_loan: false,
      is_unlimited: false,
      bank_transfer: false,
      auto_reminders: false,
      invoice_volume: 0,
      online_banking: false,
      inflow_payments: false,
      report_download: false,
      user_permission: false,
      outflow_payments: false,
      approval_workflow: false,
      recurring_billing: false,
      bill_pdf_generator: false,
      custom_integration: false,
      auto_reconciliation: false,
      auto_bill_generation: false,
      invoice_pdf_generator: false,
      transactions_per_annum: 0,
      transactions_per_month: 0,
      transactions_per_quarter: 0,
      cloud_accounting_software: false
    }
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const newValue = type === 'number' ? parseInt(value) : value;
    setSubscriptionData({ ...subscriptionData, [name]: newValue });
    setIsFormValid(true); 
  };

  const handleCostChange = (fieldName) => (newValue) => {
    setSubscriptionData((prevData) => ({
      ...prevData,
      [fieldName]: newValue
    }));
  };

  const handleDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : type === 'number' ? parseInt(value) : value;
    setSubscriptionData({
      ...subscriptionData,
      data: { ...subscriptionData.data, [name]: newValue }
    });
    setIsFormValid(true); 
  };

  const handleUnlimited = (e, fieldName) => {
    const { value } = e.target;
    const is_unlimited = value === 'true';
    setSubscriptionData((prevData) => ({
      ...prevData,
      data: { ...prevData.data, [fieldName]: is_unlimited},
      disableOtherInputs: is_unlimited,
    }));
  };

  const handleCreateSubscription = useMutation({
    mutationFn: (data) => createSubscription(data),
    onSuccess: () => {
      setSubscriptionData({
        name: '',
        monthlyCost: 0,
        quarterlyCost: 0,
        annualCost: 0,
        status: '',
        data: {
          inbox: false,
          users: 0,
          outbox: false,
          debit_card: false,
          bill_volume: 0,
          bulk_upload: false,
          credit_line: false,
          file_storage: 0,
          instant_loan: false,
          is_unlimited: false,
          bank_transfer: false,
          auto_reminders: false,
          invoice_volume: 0,
          online_banking: false,
          inflow_payments: false,
          report_download: false,
          user_permission: false,
          outflow_payments: false,
          approval_workflow: false,
          recurring_billing: false,
          bill_pdf_generator: false,
          custom_integration: false,
          auto_reconciliation: false,
          auto_bill_generation: false,
          invoice_pdf_generator: false,
          transactions_per_annum: 0,
          transactions_per_month: 0,
          transactions_per_quarter: 0,
          cloud_accounting_software: false
        }
      });
      queryClient.invalidateQueries(['subscriptions']);
      navigate('/subscriptions');
      toast.success('Subscription plan created successfully');
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message);
    }
  });

  const requiredFields = [
    'users',
    'bill_volume',
    'invoice_volume',
    'file_storage',
    'transactions_per_annum',
    'transactions_per_month',
    'transactions_per_quarter',
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const isFormValid = requiredFields.every((field) => subscriptionData.data[field] > 0);
    if (!isFormValid) {
      setIsFormValid(false);
      toast.error('Please fill in the required fields')
      return;
    }
    handleCreateSubscription.mutate(subscriptionData);
  }

  const handleReturn = () => {
    window.history.back();
  };

  return (
    <>
      <PageHeader title="Create Subscription" subtitle="Create a new subscription plan" />
      <CreateView>
        <div className="return" onClick={handleReturn}>
          <BackIcon />
        </div>
        <form onSubmit={handleFormSubmit}>
          <DataView>
            <InputView>
              <LabelView>
                <label htmlFor="plan">Plan</label>
              </LabelView>
              <Input
                required
                type="text"
                name="name"
                placeholder="Enter subscription plan"
                value={subscriptionData.name}
                onChange={handleChange}
              />
            </InputView>
            <InputView>
              <LabelView>
                <label htmlFor="plan">Subscription Mode</label>
              </LabelView>
              <select
                value={subscriptionData.data.is_unlimited}
                onChange={(e) => handleUnlimited(e, 'is_unlimited')}>
                <option value="" disabled hidden>Select</option>
                <option value={true}>Unlimited</option>
                <option value={false}>Limited</option>
              </select>
              <button className="icon" type="button">
                <ArrowDownIcon />
              </button>
            </InputView>
          </DataView>
          <DataView>
            <InputView>
              <LabelView>
                <label htmlFor="plan">Cost per month</label>
              </LabelView>
              <CurrencyyInput
                name="monthlyCost"
                className={subscriptionData.data.is_unlimited ? 'disabled' : ''}
                value={subscriptionData.monthlyCost}
                onValueChange={handleCostChange('monthlyCost')}
                disabled={subscriptionData.data.is_unlimited}
                placeholder="Enter monthly cost"
              />
            </InputView>
            <InputView>
              <LabelView>
                <label htmlFor="plan">Cost per quarter</label>
              </LabelView>
              <CurrencyyInput
                name="quarterlyCost"
                className={subscriptionData.data.is_unlimited ? 'disabled' : ''}
                value={subscriptionData.quarterlyCost}
                onValueChange={handleCostChange('quarterlyCost')}
                disabled={subscriptionData.data.is_unlimited}
                placeholder="Enter quarterly cost"
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView>
              <LabelView>
                <label htmlFor="plan">Cost per year</label>
              </LabelView>
              <CurrencyyInput
                name="annualCost"
                className={subscriptionData.data.is_unlimited ? 'disabled' : ''}
                value={subscriptionData.annualCost}
                onValueChange={handleCostChange('annualCost')}
                disabled={subscriptionData.data.is_unlimited}
                placeholder="Enter annual cost"
              />
            </InputView>
            <InputView>
              <LabelView>
                <label htmlFor="plan">Users</label>
              </LabelView>
              <Input
                required
                type="number"
                name="users"
                className={subscriptionData.data.is_unlimited ? 'disabled' : ''}
                placeholder="Enter no. of users"
                value={subscriptionData.data.users}
                disabled={subscriptionData.data.is_unlimited}
                onChange={(e) => handleDataChange(e, 'users')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView>
              <LabelView>
                <label htmlFor="plan">Invoice volume</label>
              </LabelView>
              <Input
                required
                type="number"
                name="invoice_volume"
                className={subscriptionData.data.is_unlimited ? 'disabled' : ''}
                placeholder="Enter invoice volume limit"
                value={subscriptionData.data.invoice_volume}
                disabled={subscriptionData.data.is_unlimited}
                onChange={(e) => handleDataChange(e, 'invoice_volume')}
              />
            </InputView>
            <InputView>
              <LabelView>
                <label htmlFor="plan">Bill volume</label>
              </LabelView>
              <Input
                required
                type="number"
                name="bill_volume"
                className={subscriptionData.data.is_unlimited ? 'disabled' : ''}
                placeholder="Enter bill volume limit"
                value={subscriptionData.data.bill_volume}
                disabled={subscriptionData.data.is_unlimited}
                onChange={(e) => handleDataChange(e, 'bill_volume')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView>
              <LabelView>
                <label htmlFor="plan">File Storage</label>
              </LabelView>
              <Input
                required
                type="number"
                name="file_storage"
                className={subscriptionData.data.is_unlimited ? 'disabled' : ''}
                placeholder="Enter file storage limit"
                value={subscriptionData.data.file_storage}
                disabled={subscriptionData.data.is_unlimited}
                onChange={(e) => handleDataChange(e, 'file_storage')}
              />
            </InputView>
            <InputView>
              <LabelView>
                <label htmlFor="plan">Monthly transactions</label>
              </LabelView>
              <Input
                required
                type="number"
                name="transactions_per_month"
                className={subscriptionData.data.is_unlimited ? 'disabled' : ''}
                placeholder="Enter monthly transaction limit"
                value={subscriptionData.data.transactions_per_month}
                disabled={subscriptionData.data.is_unlimited}
                onChange={(e) => handleDataChange(e, 'transactions_per_month')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView>
              <LabelView>
                <label htmlFor="plan">Quarterly transactions</label>
              </LabelView>
              <Input
                required
                type='number'
                name="transactions_per_quarter"
                className={subscriptionData.data.is_unlimited ? 'disabled' : ''}
                placeholder="Enter quarterly transaction limit"
                value={subscriptionData.data.transactions_per_quarter}
                disabled={subscriptionData.data.is_unlimited}
                onChange={(e) => handleDataChange(e, 'transactions_per_quarter')}
              />
            </InputView>
            <InputView>
              <LabelView>
                <label htmlFor="plan">Yearly transactions</label>
              </LabelView>
              <Input
                required
                type="number"
                name="transactions_per_annum"
                className={subscriptionData.data.is_unlimited ? 'disabled' : ''}
                placeholder="Enter yearly transaction limit"
                value={subscriptionData.data.transactions_per_annum}
                disabled={subscriptionData.data.is_unlimited}
                onChange={(e) => handleDataChange(e, 'transactions_per_annum')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Inbox</label>
              </LabelView>
              <Input
                width="5%"
                type="checkbox"
                name="inbox"
                className="radio-input"
                value={subscriptionData.data.inbox}
                onChange={(e) => handleDataChange(e, 'inbox')}
              />
            </InputView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Outbox</label>
              </LabelView>
              <Input
                width="5%"
                name="outbox"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.outbox}
                onChange={(e) => handleDataChange(e, 'outbox')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Debit card</label>
              </LabelView>
              <Input
                width="5%"
                name="debit_card"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.debit_card}
                onChange={(e) => handleDataChange(e, 'debit_card')}
              />
            </InputView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Bulk upload</label>
              </LabelView>
              <Input
                width="5%"
                name="bulk_upload"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.bulk_upload}
                onChange={(e) => handleDataChange(e, 'bulk_upload')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Credit line</label>
              </LabelView>
              <Input
                width="5%"
                name="credit_line"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.credit_line}
                onChange={(e) => handleDataChange(e, 'credit_line')}
              />
            </InputView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Instant Loan</label>
              </LabelView>
              <Input
                width="5%"
                name="instant_loan"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.instant_loan}
                onChange={(e) => handleDataChange(e, 'instant_loan')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Bank transfer</label>
              </LabelView>
              <Input
                width="5%"
                name="bank_transfer"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.bank_transfer}
                onChange={(e) => handleDataChange(e, 'bank_transfer')}
              />
            </InputView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Auto reminders</label>
              </LabelView>
              <Input
                width="5%"
                name="auto_reminders"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.auto_reminders}
                onChange={(e) => handleDataChange(e, 'auto_reminders')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Online banking</label>
              </LabelView>
              <Input
                width="5%"
                name="online_banking"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.online_banking}
                onChange={(e) => handleDataChange(e, 'online_banking')}
              />
            </InputView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Inflow payments</label>
              </LabelView>
              <Input
                width="5%"
                name="inflow_payments"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.inflow_payments}
                onChange={(e) => handleDataChange(e, 'inflow_payments')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Outflow payments</label>
              </LabelView>
              <Input
                width="5%"
                name="outflow_payments"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.outflow_payments}
                onChange={(e) => handleDataChange(e, 'outflow_payments')}
              />
            </InputView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Report download</label>
              </LabelView>
              <Input
                width="5%"
                name="report_download"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.report_download}
                onChange={(e) => handleDataChange(e, 'report_download')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">User permission</label>
              </LabelView>
              <Input
                width="5%"
                name="user_permission"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.user_permission}
                onChange={(e) => handleDataChange(e, 'user_permission')}
              />
            </InputView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Approval workflow</label>
              </LabelView>
              <Input
                width="5%"
                name="approval_workflow"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.approval_workflow}
                onChange={(e) => handleDataChange(e, 'approval_workflow')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Recurring bill</label>
              </LabelView>
              <Input
                width="5%"
                name="recurring_bill"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.recurring_bill}
                onChange={(e) => handleDataChange(e, 'recurring_billing')}
              />
            </InputView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Bill pdf generator</label>
              </LabelView>
              <Input
                width="5%"
                name="bill_pdf_generator"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.bill_pdf_generator}
                onChange={(e) => handleDataChange(e, 'bill_pdf_generator')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Invoice pdf generator</label>
              </LabelView>
              <Input
                width="5%"
                name="invoice_pdf_generator"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.invoice_pdf_generator}
                onChange={(e) => handleDataChange(e, 'invoice-pdf_generator')}
              />
            </InputView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Custom integration</label>
              </LabelView>
              <Input
                width="5%"
                name="custom_integration"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.custom_integration}
                onChange={(e) => handleDataChange(e, 'custom_integration')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Auto reconciliation</label>
              </LabelView>
              <Input
                width="5%"
                name="auto_reconciliation"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.auto_reconciliation}
                onChange={(e) => handleDataChange(e, 'auto_reconciliation')}
              />
            </InputView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Auto bill generation</label>
              </LabelView>
              <Input
                width="5%"
                name="auto_bill_generation"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.auto_bill_generation}
                onChange={(e) => handleDataChange(e, 'auto_bill_generation')}
              />
            </InputView>
          </DataView>
          <DataView>
            <InputView className="check">
              <LabelView>
                <label htmlFor="plan">Cloud accounting software</label>
              </LabelView>
              <Input
                width="5%"
                name="cloud_accounting_software"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.cloud_accounting_software}
                onChange={(e) => handleDataChange(e, 'cloud_accounting_software')}
              />
            </InputView>
            <InputView className="check">
              {/* <LabelView>
                <label htmlFor="plan">Unlimited</label>
              </LabelView>
              <Input
                width="5%"
                name="is_unlimited"
                type="checkbox"
                className="radio-input"
                value={subscriptionData.data.is_unlimited}
                onChange={(e) => handleUnlimited(e, 'is_unlimited')}
              /> */}
            </InputView>
          </DataView>
          <ButtonView>
            <Button
              width="35%"
              type="submit"
              className={isFormValid ? '' : 'btn-disabled'}
              disabled={!isFormValid}
            >
              {handleCreateSubscription.isLoading ? (
                <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
              ) : (
                'Create Subscription'
              )}
            </Button>
          </ButtonView>
        </form>
      </CreateView>
    </>
  );
};

export default CreateSubscription;

const CreateView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;

  .return {
    width: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

const DataView = styled.div`
  width: 100%;
  padding: 0 5rem;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: center;
  gap: 3.75rem;

  .disabled {
    background-color: #f2f2f2;
    color: #999999;
  }

  .check {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 0;
    gap: 1rem;

    .radio-input {
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 26px;
  position: relative;

  .currency-input {
    width: 100%;
    height: 64px;
    outline: none;
    margin-top: 5px;
    font-size: 1rem;
    font-weight: 300;
    text-indent: 15px;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
    border: 1px solid ${({ theme }) => theme.colors?.inputBackground};

    &:focus {
      background-color: ${({ theme }) => theme.colors?.white};
      border: 1px solid ${({ theme }) => theme.colors?.activeTitle};
    }

    &:disabled {
      background-color: #f2f2f2;
      color: #999999;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 1rem;
      font-weight: 300;
    }
  }

  select {
    padding: 0 1rem;
    margin-top: 5px;
    width: 100%;
    height: 75%;
    border-radius: 5px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 20px;
    font-weight: 600;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
  }

  .icon {
    background-color: transparent;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    position: absolute;
    top: 2.85rem;
    right: 12px;
    cursor: pointer;

    svg {
      transition: all 0.3s ease-in-out;
      transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
      transform-origin: 50% 50%;
      width: 20px;
      height: 15px;
    }
  } 

  .input {
    position: relative;

    button {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 5px;
    }
  }

  .error-label {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.error_200};
    margin-top: 5px;
  }
`;

const LabelView = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0px;

  label {
    font-size: 1rem;
  }
`;

const ButtonView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 40px 0;

  .btn-disabled {
    cursor: not-allowed;
    color: #F2F2F2;
    background-color: ${({ theme }) => theme.colors?.info};
  }
`;
