import { styled } from 'styled-components';
import AccountsTable from './AccountsTable';
import { ReactComponent as AddIcon } from '@/assets/icons/add.icon.svg';
import CreateAccountModal from './CreateAccountModal';
import { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import useOnClickOutside from '@/hooks/useClickOutside';
import CreateModuleModal from '../modules/CreateModuleModal';
import { usePermissions } from '@/hooks/usePermissions';
import toast from 'react-hot-toast';

function Accounts() {
  const [showAddModal, setShowAddModal] = useState(false);

  const { hasPermission } = usePermissions();
  const canCreateSettings = hasPermission('settings', 'create');

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const handleCreate = () => {
    if(!canCreateSettings) {
      toast.error('You do not have the permission to create');
      return;
    } else {
      setShowAddModal(true);
    }
  }

  return (
    <AccountsView>
      <TitleView>
        <h3>Admin accounts</h3>
        <p>
          Add or remove admin accounts. Admins have a role based access to manage and operate the
          app.
        </p>
      </TitleView>

      <AddAccountView>
        <button onClick={() => handleCreate()}>
          <AddIcon /> Add account
        </button>
      </AddAccountView>

      <AccountsTable />

      <CreateAccountModal 
        showModal={showAddModal} 
        setShowModal={setShowAddModal} 
      />
    </AccountsView>
  );
}

export default Accounts;

const AccountsView = styled.div`
  width: 100%;
`;

const TitleView = styled.div`
  margin-bottom: 30px;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors?.secondary};
  }

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.activeTitle};
    max-width: 50%;
  }
`;

const AddAccountView = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  button {
    position: relative;
    padding: 16px 32px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors?.primary};
    color: ${({ theme }) => theme.colors?.white};
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 8px;

      path {
        stroke: ${({ theme }) => theme.colors?.white};
      }
    }
  }

  /* .menu-actions {
    position: absolute;
    top: 300px;
    right: 20px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 200px;
    border: none;

    div {
      width: 100%;
      cursor: pointer;
      padding: 16px 12px;
      font-size: 0.875rem;
      background-color: transparent;
      transition: all 0.2s ease-in-out;
      border: none;
      outline: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:hover {
        background-color: ${({ theme }) => theme.colors.layer3};
      }

      span {
        color: ${({ theme }) => theme.colors.activeTitle};
        width: calc(100% - 35px);
        display: flex;
        justify-content: flex-start;
        text-align: left;
        font-weight: 400;
      }
    }
  } */
`;
