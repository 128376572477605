import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { deactivateUser } from '@/api/userApi';
import { fetchAccount } from '@/api/accountsApi';
import { format } from 'date-fns';
import { fetchLender } from '@/api/lendersApi';

function LenderDetailsModal({ showModal, setShowModal, selectedId }) {
  const details = useQuery({
    queryKey: ['lender', { id: selectedId }],
    queryFn: fetchLender
  });

  const detail = details.data?.data;

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <div className="header">
                <button type="button" onClick={() => setShowModal(false)}>
                  <CloseIcon />
                </button>
              </div>
              <ContentView>
                <div className="content">
                  <DataView>
                    <FormData>
                      <div className="item">
                        <div className="label-view">
                          <h3>Company Name</h3>
                          <p>{detail?.companies[0]?.name || '-'}</p>
                        </div>
                      </div>
                      <div className="item">
                        <div className="label-view">
                          <h3>Firstname</h3>
                          <p>{detail?.firstName || '-'}</p>
                        </div>
                      </div>
                      <div className="item">
                        <div className="label-view">
                          <h3>Lastname</h3>
                          <p>{detail?.lastName || '-'}</p>
                        </div>
                      </div>
                      <div className="item">
                        <div className="label-view">
                          <h3>Username</h3>
                          <p>{detail?.username || '-'}</p>
                        </div>
                      </div>
                      <div className="item">
                        <div className="label-view">
                          <h3>E-mail address</h3>
                          <p>{detail?.email || '-'}</p>
                        </div>
                      </div>
                      <div className="item">
                        <div className="label-view">
                          <h3>Role</h3>
                          <p className="capitalize">{detail?.role || '-'}</p>
                        </div>
                      </div>
                      <div className="item">
                        <div className="label-view">
                          <h3>Status</h3>
                          <Status status={detail?.status}>{detail?.status}</Status>
                        </div>
                      </div>
                      <div className="item">
                        <div className="label-view">
                          <h3>Date Created</h3>
                          <p>
                            {detail?.createdAt
                              ? format(new Date(detail?.createdAt), 'do MMM, yyyy')
                              : '--'}
                          </p>
                        </div>
                      </div>
                    </FormData>
                  </DataView>
                </div>
              </ContentView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default LenderDetailsModal;

const Status = styled.p`
  color: ${({ status }) => {
    switch (status) {
      case 'ACTIVE':
        return '#16A34A';
      case 'inactive':
        return '#B91C1C';

      default:
    }
  }};
  text-transform: capitalize;
`;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 40vw;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .icon {
      path {
        stroke: ${(props) => props.theme.colors?.error_200};
      }
    }

    .green-icon {
      path {
        stroke: ${(props) => props.theme.colors?.warning};
      }
    }

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 10px;
      color: ${(props) => props.theme.colors?.secondary};
      margin-top: 10px;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors?.darkGray};
      margin-bottom: 20px;
      line-height: 19px;
    }

    .warn {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      margin: 10px 0;
      background-color: #fdebeb;
      font-weight: 500;
      border-radius: 10px;

      h2 {
        width: 100%;
        font-weight: 500;
        line-height: 19px;
        color: ${(props) => props.theme.colors?.error_200};
      }
    }

    .btn {
      width: 100%;
      gap: 1rem;
      display: flex;
      align-items: center;

      .delete {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        background-color: ${(props) => props.theme.colors?.error_200};
        height: 64px;
        border-radius: 16px;
        color: ${(props) => props.theme.colors?.white};
        font-weight: 500;
      }

      .continue {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        background-color: ${(props) => props.theme.colors?.white};
        border: 1px solid ${(props) => props.theme.colors?.info};
        height: 64px;
        border-radius: 16px;
        color: ${(props) => props.theme.colors?.info};
        font-weight: 500;
      }
    }
  }
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const FormData = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 5px 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: ${({ theme }) => theme?.colors?.secondary};

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
      }

      .capitalize {
        text-transform: capitalize;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;