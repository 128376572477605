import PageHeader from '@/components/PageHeader';
import { styled } from 'styled-components';
import withAuthentication from '@/hooks/withAuthentication';
import InvoiceTable from './components/InvoicesTable';
import { usePermissions } from '@/hooks/usePermissions';
import { EmptyState } from '@/components/EmptyState';

function Invoices() {
  const { hasPermission } = usePermissions();
  const canReadInvoiceDiscounting = hasPermission('invoice-discounting', 'read');

  return (
    <>
      <PageHeader title="Invoices" subtitle="View and manage all Invoices" />
      <InvoicesView>
        {canReadInvoiceDiscounting ? (<InvoiceTable /> ) : (<EmptyState />)}
      </InvoicesView>
    </>
  );
}

export default withAuthentication(Invoices);

const InvoicesView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;
