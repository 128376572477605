import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/nav-down.svg';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { createAccount, getModules, getRoles } from '@/api/accountsApi';
import Button from '@/components/Button';
import Input from '@/components/Input';
import { useRef, useState } from 'react';
import SearchDropdown from '@/components/SearchDropdown';
import useOnClickOutside from '@/hooks/useClickOutside';

const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const schema = yup
  .object({
    email: yup
      .string()
      .required('Email is required')
      .matches(emailRegEx, 'Must be a valid email')
      .max(255),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password should not be less than 8 characters'),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required')
  })
  .required();

const adminRoles = [
  {
    name: 'Admin',
    value: 'admin',
    id: 'admin'
  },
  {
    name: 'Staff',
    value: 'staff',
    id: 'staff'
  },
  {
    name: 'Customer care',
    value: 'customer-care',
    id: 'customer-care'
  }
];

function CreateAccountModal({ showModal, setShowModal }) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [permissions, setPermissions] = useState([]);
  // const [role, setRole] = useState(adminRoles[0]);

  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));

  const roles = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles
  });

  const admin = roles?.data?.data?.roles;
  // console.log(admin);

  // const [role, setRole] = useState(admin?.roles[0]);

  const modules = useQuery({
    queryKey: ['modules'],
    queryFn: getModules
  });

  const module = modules?.data?.data?.modules;

  const handleCreateAccount = useMutation(createAccount, {
    onSuccess: () => {
      toast.success('Account created successfully');
      queryClient.invalidateQueries({ queryKey: ['accounts'] });
      setShowModal(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleRoleSelect = (role) => {
    setSelected(role);
    setPermissions(
      module.map((mod) => ({
        moduleId: mod.id,
        create: false,
        update: false,
        delete: false,
        read: true
      }))
    );
  };

  const handlePermissionChange = (moduleId, permission, value) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((p) => (p.moduleId === moduleId ? { ...p, [permission]: value } : p))
    );
    console.log(permissions);
  };

  const onSubmit = (data) => {
    const formData = {
      ...data,
      role: selected?.name,
      roleId: selected?.id,
      permissions
    };
    console.log(formData);
    handleCreateAccount.mutate(formData);
  };

  const resetForm = () => {
    setSelected(null);
    setPermissions([]);
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <ContentView>
                <HeaderView>
                  <h3>Create admin account</h3>
                  <button
                    className="close"
                    onClick={() => {
                      resetForm();
                      setShowModal(false);
                    }}>
                    <CloseIcon />
                  </button>
                </HeaderView>

                <Content>
                  <FormView onSubmit={handleSubmit(onSubmit)}>
                    <InputView>
                      <LabelView>
                        <label htmlFor="email">First name</label>
                      </LabelView>
                      <Input
                        type="text"
                        placeholder="Enter first name."
                        {...register('firstName')}
                        error={!!errors?.firstName?.message}
                      />
                      {errors.firstName && (
                        <span className="error-label">{errors.firstName.message}</span>
                      )}
                    </InputView>

                    <InputView>
                      <LabelView>
                        <label htmlFor="email">Last name</label>
                      </LabelView>
                      <Input
                        type="text"
                        placeholder="Enter last name."
                        {...register('lastName')}
                        error={!!errors?.lastName?.message}
                      />
                      {errors.lastName && (
                        <span className="error-label">{errors.lastName.message}</span>
                      )}
                    </InputView>

                    <InputView>
                      <LabelView>
                        <label htmlFor="email">Email</label>
                      </LabelView>
                      <Input
                        type="email"
                        placeholder="Enter company email."
                        {...register('email')}
                        error={!!errors?.email?.message}
                      />
                      {errors.email && <span className="error-label">{errors.email.message}</span>}
                    </InputView>

                    <InputView>
                      <LabelView>
                        <label htmlFor="email">Role</label>
                      </LabelView>

                      {/* <SearchDropdown
                        options={adminRoles}
                        id="id"
                        name="name"
                        setValue={setRole}
                        value={role}
                        placeholder="Select account role"
                      /> */}

                      <View ref={ref}>
                        <div className="value" onClick={() => setOpen(!open)}>
                          {selected ? (
                            <p className="val">{selected?.name}</p>
                          ) : (
                            <span className="placeholder">Select a role</span>
                          )}
                          <button className="icon" type="button">
                            <ArrowDownIcon />
                          </button>
                        </div>
                        {open ? (
                          <div className="dropdown">
                            <div className="options">
                              {admin.map((role) => (
                                <div
                                  key={role.id}
                                  className="option"
                                  onClick={() => {
                                    setOpen(false);
                                    handleRoleSelect(role);
                                  }}>
                                  {role?.name}
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </View>
                    </InputView>

                    <InputView>
                      <LabelView>
                        <label htmlFor="email">Password</label>
                      </LabelView>
                      <Input
                        type="text"
                        placeholder="Enter password."
                        width="100%"
                        height="64px"
                        {...register('password')}
                        error={!!errors?.password?.message}
                      />
                      {errors.password && (
                        <span className="error-label">{errors.password.message}</span>
                      )}
                    </InputView>

                    {selected ? (
                      <InputView>
                        <LabelView>
                          <label htmlFor="permissions">Permissions</label>
                        </LabelView>

                        <div className="permissions-container">
                          {module.map((mod, index) => (
                            <div key={index} className="module-item">
                              <span>{mod.name}:</span>
                              <div className="permissions">
                                {/* {['create', 'update', 'delete', 'read'].map((permission) => (
                                  <label key={permission}>
                                    <input
                                      type="radio"
                                      name={`module-${mod.id}-${permission}`}
                                      checked={permissions[index] && permissions[index][permission] === true}
                                      onChange={() => handlePermissionChange(mod.id, permission, true)}
                                    />
                                    True
                                    <input
                                      type="radio"
                                      name={`module-${mod.id}-${permission}`}
                                      checked={permissions[index] && permissions[index][permission] === false}
                                      onChange={() => handlePermissionChange(mod.id, permission, false)}
                                    />
                                    False
                                  </label>
                                ))} */}
                                {['create', 'read', 'update', 'delete'].map((permission) => (
                                  <label key={permission} className="permission-label">
                                    <input
                                      type="checkbox"
                                      checked={permissions[index] && permissions[index][permission]}
                                      onChange={(e) =>
                                        handlePermissionChange(mod.id, permission, e.target.checked)
                                      }
                                    />
                                    {permission.charAt(0).toUpperCase() + permission.slice(1)}
                                  </label>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </InputView>
                    ) : null}

                    <ButtonView>
                      <Button type="submit" styletype="primary">
                        {handleCreateAccount.isLoading ? (
                          <Oval
                            color="#FFF"
                            height={30}
                            width={30}
                            ariaLabel="loading"
                            strokeWidth={5}
                            secondaryColor="#eee"
                          />
                        ) : (
                          'Create account'
                        )}
                      </Button>
                    </ButtonView>
                  </FormView>
                </Content>
              </ContentView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default CreateAccountModal;

CreateAccountModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;
    margin-bottom: 50px;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;
`;

const HeaderView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
  }

  .close {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #f3f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormView = styled.form`
  width: 500px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 26px;

  .error-label {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.error_200};
    margin-top: 5px;
  }

  .permissions-container {
    .module-item {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      span {
        font-weight: 500;
      }

      .permissions {
        display: flex;
        gap: 10px;
        margin-top: 5px;

        .permission-label {
          display: flex;
          font-size: 12px;
          align-items: center;
          margin-right: 10px;
          /* padding-left: 5px; */

          /* &input {
            margin-right: 20px;
          } */

          input[type='checkbox'] {
            /* appearance: none;
            -webkit-appearance: none; */
            display: flex;
            align-content: center;
            justify-content: center;
            /* font-size: 2rem; */
            /* padding: 0.1rem; */
            margin-right: 5px;
            accent-color: ${({ theme }) => theme.colors?.secondary};
            border: 1px solid ${({ theme }) => theme.colors?.secondary};
            border-radius: 50%;
          }
        }
      }
    }
  }
`;

const LabelView = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0px;

  label {
    font-size: 1rem;
  }
`;

const ButtonView = styled.div`
  width: 100%;
  margin: 50px 0 0px 0;
`;

const View = styled.div`
  width: 100%;
  position: relative;
  margin-top: 8px;

  border-radius: 4px;
  /* margin-top: 5px; */

  .value {
    width: 100%;
    height: 64px;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
    border-radius: 16px;
    outline: none;
    padding: 16px;
    margin-top: 5px;
    transition: all 0.25s ease-in-out;
    position: relative;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      background-color: transparent;
      padding: 0;
      margin: 0;
      outline: none;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 25px;
      right: 12px;
      cursor: pointer;

      svg {
        transition: all 0.3s ease-in-out;
        transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
        transform-origin: 50% 50%;
        width: 15px;
        height: 15px;
      }
    }

    p {
      color: ${({ theme }) => theme.colors?.activeTitle};
      text-transform: capitalize;
      font-weight: 400;
    }

    .placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
    }
  }

  .dropdown {
    width: 100%;
    z-index: 20;
    position: absolute;
    top: 70px;
    background-color: ${({ theme }) => theme.colors?.white};
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .input {
      outline: none;
      margin-top: 0;
      padding: 10px;
      /* margin-bottom: 10px; */
      font-size: 0.8rem;
      font-weight: 300;
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
      width: 100%;
      height: 50px;
      /* position: absolute;
      top: 0px;
      left: 0px; */
    }

    .options {
      width: 100%;
      max-height: 220px;
      padding-top: 10px;
      overflow-y: scroll;

      .option {
        padding: 12px 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 0.875rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        width: 100%;
        text-transform: capitalize;
        color: ${({ theme }) => theme.colors?.activeTitle};

        &:hover {
          background-color: ${({ theme }) => theme.colors?.layer};
          border-radius: 0;
        }

        .logo {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          display: inline-block;
          border: 1px solid ${({ theme }) => theme.colors?.line};
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 20px;
            height: 20px;
            object-fit: cover;
          }
        }
      }
    }
  }
`;
