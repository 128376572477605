import PageHeader from '@/components/PageHeader';
import { styled } from 'styled-components';
import withAuthentication from '@/hooks/withAuthentication';
import TransfersTable from './components/TransfersTable';
import TransfersMetrics from './components/TransfersMetrics';
import { usePermissions } from '@/hooks/usePermissions';
import { EmptyState } from '@/components/EmptyState';
import { TransferStateTabs } from './components/TransferStateTabs';

function Transfers() {
  const { role, hasPermission } = usePermissions();
  const canReadTransfers = hasPermission('transfer', 'read');
  const isRoleSuper = role('Super Admin');
  const isRoleAdmin = role('Admin');

  return (
    <>
      <PageHeader title="Transfers" subtitle="View and manage all Transfers" />
      <TransfersView>
        {canReadTransfers || isRoleSuper || isRoleAdmin ? (
          <div>
            <TransfersMetrics />
            <TransferStateTabs />
            {/* <TransfersTable /> */}
          </div>
        ) : (<EmptyState />)}
      </TransfersView>
    </>
  );
}

export default withAuthentication(Transfers);

const TransfersView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;
