import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/auth/Login';
import Layout from './components/Layout';
import { RedirectRoute } from './pages/RedirectRoutes';
import Dashboard from './pages/dashboard/Dashboard';
import ErrorPage from './pages/ErrorPage';
import Bills from './pages/bills/Bills';
import Invoices from './pages/invoices/Invoices';
import Transactions from './pages/transactions/Transactions';
import Users from './pages/users/Users';
import Compliance from './pages/compliance/Compliance';
import ComplianceDetails from './pages/compliance/ComplianceDetails';
import Profile from './pages/profile/Profile';
import Settings from './pages/settings/Settings';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import ResetPassword from './pages/resetPassword/ResetPassword';
import User from './pages/users/User';
import BillsDetails from './pages/bills/BillsDetails';
import InvoiceDetails from './pages/invoices/InvoiceDetails';
import TransactionDetails from './pages/transactions/TransactionsDetails';
import Subscriptions from './pages/subscriptions/Subscriptions';
import CreateSubscription from './pages/subscriptions/CreateSubscription';
import EditSubscription from './pages/subscriptions/EditSubscription';
import Wallets from './pages/wallets/Wallets';
import WalletDetails from './pages/wallets/WalletDetails';
import Transfers from './pages/transfers/Transfers';
import TransferDetails from './pages/transfers/TransferDetails';
import VirtualAccounts from './pages/virtualAccounts/VirtualAccounts';
import VirtualAccountDetails from './pages/virtualAccounts/VirtualAccountDetails';
import BillFinancing from './pages/billFinancing/BillFinancing';
import UpdateTransactionFee from './pages/transactions/components/fees/UpdateTransactionFee';
import Terms from './pages/settings/components/terms/Terms';
import InvoiceDiscount from './pages/invoiceDiscount/InvoiceDiscount';
import Leaderboard from './pages/leaderboard/Leaderboard';
import Details from './pages/leaderboard/components/Details';
import { WebHook } from './pages/webhooks/WebHook';
import { WebhookDetails } from './pages/webhooks/components/WebhookDetails';
import InflowDetails from './pages/transfers/InflowsDetails';
import OutflowsDetails from './pages/transfers/OutflowsDetails';
import { HistoryDetails } from './pages/wallets/components/details/HistoryDetails';
import { Lenders } from './pages/lenders/Lenders';
import { LenderDetails } from './pages/lenders/LenderDetails';

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/password-reset" element={<ResetPassword />} />
      <Route path="/" exact element={<Layout />}>
        <Route index element={<RedirectRoute />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/bills" element={<Bills />} />
        <Route path="/bills/:id" element={<BillsDetails />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/invoices/:id" element={<InvoiceDetails />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/transactions/:id" element={<TransactionDetails />} />
        <Route path="/transactions/fees/:id" element={<UpdateTransactionFee />} />
        <Route path='/leaderboard' element={<Leaderboard />} />
        <Route path='/leaderboard/:id' element={<Details />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/subscriptions/create" element={<CreateSubscription />} />
        <Route path="/subscriptions/:id" element={<EditSubscription />} />
        <Route path="/wallets" element={<Wallets />} />
        <Route path="/wallets/:id" element={<WalletDetails />} />
        <Route path="/wallets-history/:id" element={<HistoryDetails />} />
        <Route path="/transfers" element={<Transfers />} />
        {/* <Route path="/transfers/:id" element={<TransferDetails />} /> */}
        <Route path="/transfers-inflows/:id" element={<InflowDetails />} />
        <Route path="/transfers-outflows/:id" element={<OutflowsDetails />} />
        <Route path='/virtual-accounts' element={<VirtualAccounts />} />
        <Route path="/virtual-accounts/:id" element={<VirtualAccountDetails />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<User />} />
        <Route path="/lenders" element={<Lenders />} />
        <Route path="/lenders/:id" element={<LenderDetails />} />
        <Route path="/compliance" element={<Compliance />} />
        <Route path="/compliance/:id" element={<ComplianceDetails />} />
        <Route path="/purchase-financing" element={<BillFinancing />} />
        <Route path="/invoice-discount" element={<InvoiceDiscount />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path='/webhooks' element={<WebHook />} />
        <Route path='/webhooks/:id' element={<WebhookDetails />} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
