import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { Card } from '@/components/MetricsCard';

function InvoiceMetrics({
  totalInvoices,
  totalPaidInvoices,
  totalInvoiceAmount,
  totalPaidInvoiceAmount,
  totalPartiallyPaidInvoiceSum,
  totalPartiallyPaidInvoiceCount,
  totalPartiallyUnPaidInvoiceSum,
  totalPartiallyUnPaidInvoiceCount
}) {
  return (
    <>
      <InvoiceMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(totalInvoices || '00.00')}</h3>
            <p>Total Invoices</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(totalInvoiceAmount || '00.00')}</h3>
            <p>Total Invoices Amount</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(totalPaidInvoices || '00.00')}</h3>
            <p>Paid Invoices</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(totalPaidInvoiceAmount || '00.00')}</h3>
            <p>Paid Invoices Amount</p>
          </Card>
        </div>
      </InvoiceMetricsView>

      <InvoiceMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(totalPartiallyPaidInvoiceCount || '00.00')}</h3>
            <p>Pairtially Paid Invoices</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(totalPartiallyPaidInvoiceSum || '00.00')}</h3>
            <p>Partially Paid Invoices Amount</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(totalPartiallyUnPaidInvoiceCount || '00.00')}</h3>
            <p>Unpaid Invoices</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(totalPartiallyUnPaidInvoiceSum || '00.00')}</h3>
            <p>Unpaid Invoices Amount</p>
          </Card>
        </div>
      </InvoiceMetricsView>
    </>
  );
}

export default InvoiceMetrics;

InvoiceMetrics.propTypes = {
  pending: PropTypes.number,
  verified: PropTypes.number,
  total: PropTypes.number
};

const InvoiceMetricsView = styled.div`
  width: 100%;
  height: 130px;
  border-radius: 6px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .card-wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.alternateBackground2};
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .grey-text {
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const MetricView = styled.div`
  width: 32%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({ theme }) => theme.colors.line};
`;
