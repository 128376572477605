import PageHeader from '@/components/PageHeader';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { WebhooksTable } from './components/WebhooksTable';
import { LogsTable } from './components/LogsTable';
import { usePermissions } from '@/hooks/usePermissions';
import { EmptyState } from '@/components/EmptyState';

const tabs = [
  {
    name: 'Webhooks',
    path: 'webhooks',
    value: 'webhooks'
  },
  {
    name: 'Logs',
    path: 'logs',
    value: 'logs'
  }
];
export const WebHook = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { hasPermission } = usePermissions();

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      setSearchParams('tab=wwebhooks');
      // Optionally, you can also navigate to the new URL
      navigate('?tab=webhooks');
    }
  }, [searchParams, navigate, setSearchParams]);

  const canReadWebhooks = hasPermission('webhooks', 'read');

  return (
    <>
      <WebHookView>
        <PageHeader title="Webhooks" subtitle="View and manage webhooks" />
        {canReadWebhooks ? (
          <div>
            <TabView>
              {tabs.map((item) => (
                <button
                  key={item.name}
                  className={`tab ${tab === item.value ? 'active' : ''}`}
                  onClick={() => setSearchParams(`tab=${item.path}`)}>
                  {item.name}
                </button>
              ))}
            </TabView>
            <TabContent>{tab === 'webhooks' && <WebhooksTable />}</TabContent>
            <TabContent>{tab === 'logs' && <LogsTable />}</TabContent>
          </div>
        ) : (<EmptyState />)}
      </WebHookView>
    </>
  );
};

const WebHookView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 80px;
  overflow-x: hidden;
  margin-left: 18%;
`;

const TabView = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  position: fixed;
  z-index: 100;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};

  button {
    padding: 16px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
      color: ${(props) => props.theme?.colors?.secondary};
    }
  }

  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;

const TabContent = styled.div`
  width: 100%;
`;
