import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import ApprovedTable from "./ApprovedTable";
import PendingTable from "./PendingTable";
import DeniedTable from "./DeniedTable";
import { useQuery } from "@tanstack/react-query";
import { fetchStatistics } from "@/api/billFinancingApi";
import { numberWithCommas } from "@/utils/helpers";
import CompletedTable from "./CompletedTable";

const BillFinancingTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  let tab = searchParams.get('view');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      setSearchParams('tab=purchase-financing&view=pending');
      // Optionally, you can also navigate to the new URL
      navigate('?tab=purchase-financing&view=pending');
    }
  }, [searchParams, navigate, setSearchParams]);

  const stats = useQuery({
    queryKey: ['stats'],
    queryFn: fetchStatistics,
  });

  const stat = stats.data?.data

  const tabs = [
    {
      name: "Pending",
      path: "view=pending",
      value: "pending",
      count: `${stat?.pending?.count} items`,
      price: `₦${numberWithCommas(stat?.pending?.totalAmount)}`,
    },
    {
      name: "Approved",
      path: "view=approved",
      value: "approved",
      count: `${stat?.approved?.count} items`,
      price: `₦${numberWithCommas(stat?.approved?.totalAmount)}`
    },
    {
      name: "Denied",
      path: "view=denied",
      value: "denied",
      count: `${stat?.denied?.count} items`,
      price: `₦${numberWithCommas(stat?.denied?.totalAmount)}`
    },
    {
      name: "Completed",
      path: "view=completed",
      value: "completed",
      count: `${stat?.paid?.count} items`,
      price: `₦${numberWithCommas(stat?.paid?.totalAmount)}`
    }
  ];

  return (
    <BillFinancingTableView>
      <h3 className='title'>Purchase Financing</h3>
      <ContentView>
        <div className='invoice-discount-tabs'>
          {tabs.map((tabItem) => (
            <>
              <div
                key={tabItem.path}
                className={`tabs ${tab === tabItem.value ? 'active' : ''}`}
                onClick={() => setSearchParams(`tab=bill-financing&${tabItem?.path}`)}
              >
                <h3>{tabItem.name}</h3>
                <div className="tab-values">
                  <p>{tabItem.count}</p>
                  <p>{tabItem.price}</p>
                </div>
              </div>
              <Divider />
            </>
          ))}
        </div>
        <TableView>
          {tab === 'pending' && <PendingTable />}
          {tab === 'approved' && <ApprovedTable />}
          {tab === 'denied' && <DeniedTable />}
          {tab === 'completed' && <CompletedTable />}
        </TableView>
      </ContentView>
    </BillFinancingTableView>
  )
}

export default BillFinancingTable;

const BillFinancingTableView = styled.div`
  width: 100%;
  height: 100%;

  .title {
    font-size: 24px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary};
  }

  .invoice-discount-tabs {
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;

    .tabs {
      width: 100%;
      padding: 0;
      cursor: pointer;
      color: ${({ theme }) => theme.colors?.body};

      &:hover {
        color: ${({ theme }) => theme.colors?.secondary};
      }

      h3 {
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;

        &:hover {
          color: ${({ theme }) => theme.colors?.secondary};
        }
      }

      p {
        font-size: 0.8rem;
      }

      .tab-values {
        display: flex;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: space-between;
      }
    }

    .active {
      width: contain;
      color: ${({ theme }) => theme.colors?.secondary};
      border-bottom: 4px solid ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const TableView = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentView = styled.div`
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.line};
`;