import styled from 'styled-components'

const Card = ({ children }) => {
  return (
    <CardView>
      <div className="info">
        {children}
      </div>
    </CardView>
  )
}

export { Card }

const CardView = styled.div`
  width: 13.21rem;
  height: 130px;
  display: flex;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.alternateBackground};

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.alternateBackground2};
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 1rem;
    color: ${({ theme }) => theme.colors.secondary};
    gap: 1.5rem;
    font-weight: 500;

    p {
      font-size: 12px;
    }
  }
`;