import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { persistStore } from 'redux-persist';
import toast, { Toaster } from 'react-hot-toast';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import './assets/styles/fonts.css';
import store from './redux/store';
import BasedStyles from './assets/styles/GlobalStyle';
import theme from './assets/styles/theme';
import App from './App';

let persistor = persistStore(store);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: true,
      retry: false
    }
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (error?.response?.status === 500) {
        console.log(error);
        toast.error(`Something went wrong: ${error?.response?.data?.message}`);
      }
      // if (error?.response?.status === 400) {
      //   toast.error(error?.response?.data?.message?.[0]);
      // }
      if (query.state.data !== undefined) {
        toast.error(`Something went wrong: ${error?.response?.data?.message}`);
      }
    }
  })
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster
          containerStyle={{
            display: 'flex',
            justifyContent: 'flex-start',
            margin: 0,
            padding: 0,
            borderRadius: '10px'
          }}
          toastOptions={{
            className: 'toastt',
            duration: 6000,
            zIndex: 9999,
            style: {
              padding: '10px',
              color: '#000',
              fontSize: '14px',
              fontWeight: '600',
              borderRadius: '10px',
              margin: 0,
              minHeight: '50px',
              minWidth: '300px'
            },
            success: {
              style: {
                background: '#18181B',
                color: '#FAFAFA',
                textAlign: 'left',
                justifyContent: 'flex-start'
              }
            },
            error: {
              style: {
                background: '#18181B',
                color: '#FAFAFA',
                textAlign: 'left',
                justifyContent: 'flex-start'
              }
            }
          }}
          position="top-center"
        />
        <ThemeProvider theme={theme}>
          <BasedStyles />
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <App />
            </BrowserRouter>

            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </>
);
