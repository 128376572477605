import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components';
import Daily from './Daily';
import Weekly from './Weekly';
import Monthly from './Monthly';
import AllTime from './AllTime';
import LastMonth from './LastMonth';

const Tabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let tab = searchParams.get('tab');
  const navigate = useNavigate();

  const tabs = [
    {
      name: "Daily",
      path: "daily",
      value: "daily",
    },
    {
      name: "Weekly",
      path: "weekly",
      value: "weekly",
    },
    {
      name: "Monthly",
      path: "monthly",
      value: "monthly",
    },
    {
      name: "Last Month",
      path: "last-month",
      value: "last-month",
    },
    {
      name: "All Time",
      path: "all-time",
      value: "all-time",
    }
  ];

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      setSearchParams('tab=daily');
      // Optionally, you can also navigate to the new URL
      navigate('?tab=daily');
    }
  }, [searchParams, navigate, setSearchParams]);

  return (
    <>
      <TabView>
        {tabs.map((item) => {
          return (
            <div key={item.path} className="tab-item">
              <button
                type="button"
                className={`tab ${tab === item.value ? 'active' : ''}`}
                onClick={() => setSearchParams(`tab=${item.path}`)}>
                {item.name}
              </button>
            </div>
          )
        })}
      </TabView>
      <TabContent>
        {tab === 'daily' && <Daily />}
        {tab === 'weekly' && <Weekly />}
        {tab === 'monthly' && <Monthly />}
        {tab === 'last-month' && <LastMonth />}
        {tab === 'all-time' && <AllTime />}
      </TabContent>
    </>
  )
}

export default Tabs

const TabView = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  position: fixed;
  z-index: 1000000;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};

  button {
    padding: 16px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
    color: ${(props) => props.theme?.colors?.secondary};
    }
  }
  
  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;

const TabContent = styled.div`
  width: 100%;
`;