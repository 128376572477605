import { styled } from 'styled-components';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { useQuery } from '@tanstack/react-query';
import { fetchTransferMetrics } from '@/api/transfersApi';
import { Oval } from 'react-loader-spinner';
import { Card } from '@/components/MetricsCard';
import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import { useRef, useState } from 'react';
import useOnClickOutside from '@/hooks/useClickOutside';
import { motion, AnimatePresence } from 'framer-motion';

function TransfersMetrics() {
  const [fetch, setFetch] = useState('allTime');
  const [fetchValue, setFetchValue] = useState('allTime');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDateValue, setStartDateValue] = useState('');
  const [endDateValue, setEndDateValue] = useState('');
  const [showFilterMenu, setShowFilterMenu] = useState(false);

 const filterRef = useRef();
  useOnClickOutside(filterRef, () => setShowFilterMenu(false));

  const filterOptions = [
    { value: 'allTime', label: 'All Time' },
    { value: 'currentWeek', label: 'Current Week' },
    { value: 'lastWeek', label: 'Last Week' },
    { value: 'currentMonth', label: 'Current Month' },
    { value: 'lastMonth', label: 'Last Month' },
    { value: 'currentYear', label: 'Current Year' },
    { value: 'lastYear', label: 'Last Year' }
  ];

  const transfersMetrics = useQuery({
    queryKey: ['transfersMetrics', { fetch }],
    queryFn: fetchTransferMetrics
  });

  const handleFilter = () => {
    setStartDate(startDateValue);
    setEndDate(endDateValue);
    setEndDateValue('');
    setStartDateValue('');
    setShowFilterMenu(false);
  }

  const handleFilterSubmit = () => {
    // Update the `fetchBy` state with the selected value
    setFetch(fetchValue);
    setShowFilterMenu(false);
    // Clear the selected fetch value after submission
    setFetchValue('');
  };

  if (transfersMetrics?.isFetching) {
    return (
      <LoaderView>
        <div className="loading-view">
          <Oval color="#92C22C" width={50} height={50} strokeWidth={5} />
        </div>
        ;
      </LoaderView>
    );
  }

  return (
    <>
      <Filter className="filter">
        <button className="filter-btn" onClick={() => setShowFilterMenu(true)}>
          <FilterIcon /> Filter Metrics
        </button>

        {/* ===== Filter Menu ====== */}
        <AnimatePresence>
          {showFilterMenu ? (
            <motion.div
              className="filter-menu"
              ref={filterRef}
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}>
              <div className="heading">
                <span>Filters</span>
                <button
                  className="done"
                  disabled={!fetchValue}
                  onClick={() => {
                    handleFilterSubmit();
                  }}>
                  Done
                </button>
              </div>
              <div className="body">
                <div className="item">
                  <h3>Duration</h3>
                  {filterOptions.map((option) => {
                    return (
                      <div className="input" key={option.value}>
                        <input
                          type="radio"
                          name="duration"
                          value={option.value}
                          checked={fetchValue === option.value}
                          onChange={(e) => {
                            setFetchValue(e.target.value);
                          }}
                        />
                        <label>{option.label}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Filter>

      <TransfersMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(transfersMetrics?.data?.data?.totalTransfers || '00.00')}</h3>
            <p>Total Transfers</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(transfersMetrics?.data?.data?.totalSuccessfulTransfers || '00.00')}</h3>
            <p>Total Successful Transfers</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(transfersMetrics?.data?.data?.totalPendingTransfers || '00.00')}</h3>
            <p>Total Pending Transfers</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(transfersMetrics?.data?.data?.totalFailedTransfers || '00.00')}</h3>
            <p>Total Failed Transfers</p>
          </Card>
        </div>
      </TransfersMetricsView>

      <TransfersMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>₦{numberWithCommas(transfersMetrics?.data?.data?.totalSuccessfulTransfersAmount || '00.00')}</h3>
            <p>Total Successful Transfers Amount</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(transfersMetrics?.data?.data?.totalPendingTransfersAmount || '00.00')}</h3>
            <p>Total Pending Transfers Amount</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(transfersMetrics?.data?.data?.totalFailedTransfersAmount || '00.00')}</h3>
            <p>Total Failed Transfers Amount</p>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(transfersMetrics?.data?.data?.totalAccumulatedCharges || '00.00')}</h3>
            <p>Total Accumulated Charges</p>
          </Card>
        </div>
      </TransfersMetricsView>
    </>
  );
}

export default TransfersMetrics;

const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  .filter-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      span {
        font-size: 1.1rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.black};
      }

      .done {
        background-color: transparent;
        color: ${({ theme }) => theme.colors?.secondary};
        border: none;
        outline: none;
        font-size: 0.875rem;
        font-weight: 600;
        cursor: pointer;
        margin: 0;
        padding: 0;
        width: auto;
      }
    }

    .body {
      .item {
        width: 100%;
        margin-bottom: 20px;

        h3 {
          font-size: 1rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors?.black};
          margin-bottom: 10px;
        }

        .input {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          input {
            margin-right: 10px;
            accent-color: ${({ theme }) => theme.colors?.secondary};
            cursor: pointer;
          }
        }

        select {
          width: 100%;
          height: 40px;
          outline: none;
          border: 1px solid ${({ theme }) => theme.colors.line};
          border-radius: 6px;
          padding: 0 10px;
          font-size: 0.875rem;
          font-weight: 400;
          color: ${({ theme }) => theme.colors?.placeholder};
          cursor: pointer;
        }
      }
    }
  }

  .export-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;

    .body {
      margin-top: 20px;

      .item {
        width: 100%;
        margin-bottom: 20px;

        .date-input {
          gap: 0.4rem;
          display: flex;
          align-items: start;
          margin-bottom: 10px;
          flex-direction: column;
          justify-content: start;

          input {
            width: 100%;
            border: none;
            cursor: pointer;
            margin-right: 10px;
            border-radius: 6px;
            padding: 0.5rem 0.65rem;
            color: ${({ theme }) => theme.colors?.black};
            background-color: ${({ theme }) => theme.colors?.inputBackground};
          }
        }
      }

      .done {
        margin: 0;
        width: auto;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: 600;
        border-radius: 6px;
        padding: 0.6rem 2rem;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.white};
        background-color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }

  button {
    width: 120px;
    height: 100%;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }

  .sub-update {
    width: 200px;
    background-color: ${({ theme }) => theme.colors?.secondary};
    border-radius: 6px;
    color: ${({ theme }) => theme.colors?.white};
    font-weight: 600;
    transition: all 0.2s ease-in-out;
  }
`;

const TransfersMetricsView = styled.div`
  width: 100%;
  height: 130px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;

  .card-wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.alternateBackground2};
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .grey-text {
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const LoaderView = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .loading-view {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const MetricView = styled.div`
  width: 32%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.secondary};
    word-break: break-all;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({ theme }) => theme.colors.line};
`;
